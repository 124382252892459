








































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import UserCard from "@/layouts/components/UserCard.vue";
import Calendar from "./Calendar.vue";
import { BOverlay } from "bootstrap-vue";
import { readableDay } from "@/helpers/calendar";
import { differenceInDays, startOfDay } from "date-fns";
import SolutionAppointmentAppModel from "@/api/solution_appointment_app.model";
import { AppointmentStatus } from "@/interfaces/appointment";

const today = new Date();

@Component({
	components: {
		UserCard,
		Calendar,
		BOverlay,
	},
})
export default class UserCalendar extends Vue {
	@Prop() id: string;
	@Prop({ default: "" }) modalStyle!: string;
	@Prop({ default: "" }) title!: string;
	@Prop({ default: "" }) titlesStyle!: string;
	@Prop({ default: "" }) subtitle!: string;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "" }) iconSize!: string;
	@Prop({ default: "mb-2" }) backButtonStyle!: string;
	@Prop({ default: "Voltar" }) backButtonText!: string;
	@Prop({ default: false }) isOpen!: boolean;
	@Prop({ default: false }) withBackButton!: boolean;
	@Prop({ default: null }) onBackButton!: Function;
	@Prop({ default: () => [] }) buttons!: any[];
	@Prop({ default: () => null }) onModalClosed!: () => void;

	isModalOpen = this.isOpen;

	isCalendarOpen = false;
	selectedDate = "";
	today = startOfDay(new Date()).toISOString();

	currentPage = 1;
	pageLimit = 15;

	appointments: any = [];
	areAppointmentsLoading = false;

	activeUser = this.$store.state.auth.activeUser;

	totalAppointments = 0;
	priorityAppointmentKey = 0;
	regularAppointmentKey = 0;

	priorityAppointmentsList = [];
	regularAppointmentsList = [];

	tableColumns = [
		{ key: "name", kind: "userName" },
		{ key: "lastName", kind: "userName" },
		{
			name: "Carteirinha",
			key: "insurerNumber",
			kind: "nameWithData",
		},
		{ name: "Data de Nascimento", key: "birthDate", kind: "date" },
		{ name: "CPF", key: "cpf", kind: "document" },
		{ name: "Status", key: "isActive", kind: "status" },
		{ name: "Telefone", key: "phoneNumber", kind: "phone" },
	];

	appointmentsHeaders = [
		{ key: "name", kind: "userName" },
		{ key: "lastName", kind: "userName" },
		{
			content: [
				{ key: "birthDate", kind: "age" },
				{
					key: "gender",
					kind: "status",
				},
				{ key: "corporateClient", relation: "name" },
			],
		},
	];

	data() {
		return { isModalOpen: this.isOpen };
	}

	closeModal() {
		this.isModalOpen = false;
		if (this.onModalClosed && typeof this.onModalClosed === "function") {
			this.onModalClosed();
		}
	}
	@Watch("isOpen")
	async isOpenChanged() {
		this.isModalOpen = this.isOpen;
		if (this.isModalOpen) {
			await this.fetchAppointments();
		}
	}

	openChannel(event: Event, cardData: any) {
		this.$emit("openChannel", cardData, undefined, cardData.corporateClient.id);
	}

	get readableDate() {
		return this.selectedDate ? readableDay(this.selectedDate) : readableDay(this.today);
	}

	generatePriorityAppointmentsList() {
		this.priorityAppointmentsList = this.appointments.filter(
			(data: any) =>
				differenceInDays(new Date(data.returnDate), this.selectedDate ? new Date(this.selectedDate) : today) < 0,
		);
	}

	generateRegularAppointmentsList() {
		this.regularAppointmentsList = this.appointments.filter(
			(data: any) =>
				differenceInDays(new Date(data.returnDate), this.selectedDate ? new Date(this.selectedDate) : today) >= 0,
		);
	}

	@Watch("selectedDate")
	closeCalendar() {
		this.isCalendarOpen = !this.isCalendarOpen;
	}

	async fetchAppointments() {
		this.areAppointmentsLoading = true;
		this.currentPage = 1;
		const { data, total, page } = await SolutionAppointmentAppModel.getAppointmentList({
			limit: this.pageLimit,
			page: this.currentPage,
			isWithReturn: true,
			shouldGetOnlyWithSchedule: false,
			shouldGroupByClientName: true,
			shouldOrderByAppointmentReturnDate: true,
			appointmentStatus: [AppointmentStatus.CONFIRMED],
			userId: this.activeUser.id,
			...(this.selectedDate ? { date: this.selectedDate } : { date: this.today }),
		});
		this.appointments = data;
		this.totalAppointments = total;
		this.currentPage = page;

		this.generatePriorityAppointmentsList();
		this.generateRegularAppointmentsList();

		this.areAppointmentsLoading = false;
	}

	async loadAppointments() {
		//Prevenir várias requisições
		if (this.areAppointmentsLoading === true) {
			return;
		}
		if (this.totalAppointments > this.regularAppointmentsList.length + this.priorityAppointmentsList.length) {
			this.areAppointmentsLoading = true;
			const { data, total, page } = await SolutionAppointmentAppModel.getAppointmentList({
				limit: this.pageLimit,
				page: this.currentPage + 1,
				isWithReturn: true,
				shouldGetOnlyWithSchedule: false,
				shouldGroupByClientName: true,
				shouldOrderByAppointmentReturnDate: true,
				appointmentStatus: [AppointmentStatus.CONFIRMED],
				userId: this.activeUser.id,
				...(this.selectedDate ? { date: this.selectedDate } : { date: this.today }),
			});
			this.appointments.push(...data);
			this.totalAppointments = total;
			this.currentPage = page;

			this.generatePriorityAppointmentsList();
			this.generateRegularAppointmentsList();

			this.priorityAppointmentKey += 1;
			this.regularAppointmentKey += 1;
			this.areAppointmentsLoading = false;
		}
	}
}
