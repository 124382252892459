import { render, staticRenderFns } from "./MainChatMember.vue?vue&type=template&id=665a809f&scoped=true&"
import script from "./MainChatMember.vue?vue&type=script&lang=ts&"
export * from "./MainChatMember.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "665a809f",
  null
  
)

export default component.exports