import { ISolution } from "@/interfaces/solution";
import SolutionCategoryAppModel from "./solution_category_app.model";
import SolutionInsurerPlanAppModel from "./solution_insurer_plan_app.model";
import SolutionNeedAppModel from "./solution_need_app.model";
import SolutionQualificationAppModel from "./solution_qualification_app.model";
import { CrudModel } from "./_crud";

export class SolutionAppModel extends CrudModel {
	constructor() {
		super(
			"app/solution",
			[],
			[
				{ fieldKey: "category", model: SolutionCategoryAppModel },
				{ fieldKey: "solutionNeeds", model: SolutionNeedAppModel },
				{ fieldKey: "solutionQualifications", model: SolutionQualificationAppModel },
				{ fieldKey: "solutionInsurerPlans", model: SolutionInsurerPlanAppModel },
			],
		);
	}

	updateSolutionStatus(id: string, solution: Pick<ISolution, "status">) {
		return this.patch(null, `${id}/validate`, solution);
	}
}

export default new SolutionAppModel();
