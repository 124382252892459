






















import { ChatMember } from "@/interfaces/chat";
import { Component, Vue, Prop } from "vue-property-decorator";
import ClientCard from "./ClientCard.vue";

@Component({
	components: { ClientCard },
})
export default class MainChatMember extends Vue {
	@Prop({ default: [] }) chatMembers: ChatMember[];

	clientHeader = [
		{ key: "name", kind: "userName" },
		{ key: "lastName", kind: "userName" },
		{
			content: [{ key: "phoneNumber" }, { key: "secondPhoneNumber" }, { key: "contactPhoneNumber" }],
		},
	];

	favoriteClient(cardData: any) {
		this.$emit("favoriteClient", cardData, "update");
	}

	removeRelatedClient(cardData: any) {
		this.$emit("removeRelatedClient", cardData, "delete");
	}

	get currentChatMembers() {
		return this.chatMembers.filter((member: ChatMember) => member.kind !== "USER") ?? [];
	}
}
