































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CustomButton from "@/layouts/components/Button.vue";
import Select from "@/layouts/components/Select.vue";
import MessageTemplateAppModel from "@/api/message_template_app.model";
import clientAppModel from "@/api/client_app.model";
import { showErrorAlert } from "@/helpers";
import Input from "@/layouts/components/Input.vue";
import EntityCrud from "@/views/entity/EntityCrud.vue";
import { formatDayMonthYear } from "@/helpers/utils";

@Component({
	components: {
		"custom-button": CustomButton,
		Select,
		"journey-input": Input,
		EntityCrud,
	},
})
export default class ChooseClient extends Vue {
	@Prop() id: string;
	@Prop({ default: "" }) modalStyle!: string;
	@Prop({ default: "" }) title!: string;
	@Prop({ default: "" }) titlesStyle!: string;
	@Prop({ default: "" }) subtitle!: string;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "" }) iconSize!: string;
	@Prop({ default: "mb-2" }) backButtonStyle!: string;
	@Prop({ default: "Voltar" }) backButtonText!: string;
	@Prop({ default: false }) isOpen!: boolean;
	@Prop({ default: "chat:search" }) eventType!: string;
	@Prop({ default: false }) withBackButton!: boolean;
	@Prop({ default: null }) onBackButton!: Function;
	@Prop({ default: () => [] }) buttons!: any[];
	@Prop({ default: () => null }) onModalClosed!: () => void;

	isModalOpen = this.isOpen;
	messageTemplateAppModel = MessageTemplateAppModel;
	searchParam = "";
	clientModel = clientAppModel;
	clientsList = [];
	tableColumns = [
		{ key: "name", kind: "userName" },
		{ key: "lastName", kind: "userName" },
		{
			name: "Carteirinha",
			key: "insurerNumber",
			kind: "nameWithData",
		},
		{ name: "Data de Nascimento", key: "birthDate", kind: "date", onTransform: formatDayMonthYear },
		{ name: "CPF", key: "cpf", kind: "document" },
		{ name: "Status", key: "isActive", kind: "status" },
		{ name: "Telefone", key: "phoneNumber", kind: "phone" },
	];

	searchLabel = "Digite um nome, CPF, Data de nascimento, Carteirinha ou Telefone";
	kind = "cardsTable";
	isClientsLoading: boolean = false;

	modalTitle = "Encontre um cliente";

	data() {
		return { isModalOpen: this.isOpen };
	}

	closeModal() {
		this.isModalOpen = false;
		if (this.onModalClosed && typeof this.onModalClosed === "function") {
			this.onModalClosed();
		}
	}
	@Watch("isOpen")
	isOpenChanged() {
		this.showNameByEventType();
		this.isModalOpen = this.isOpen;
	}

	async openChannel(cardData: any) {
		const foundClient = await this.clientModel.read(cardData.id);

		this.$emit(
			"openChannel",
			cardData,
			undefined,
			foundClient ? foundClient["corporateClient.id"] : undefined,
			undefined,
		);
	}

	relateClient(cardData: any) {
		this.$emit("relateClient", cardData);
	}

	findClient(cardData: any) {
		this.$emit("findClient", cardData);
	}

	async searchClient(event: Event) {
		try {
			this.isClientsLoading = true;
			const searchParam = this.searchParam?.length ? this.searchParam : undefined;
			const request = await this.clientModel.getClientList({
				page: 1,
				limit: 50,
				sort: {
					property: "client.name",
					type: "ASC",
				},
				searchParam,
			});
			this.clientsList = request.data;
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isClientsLoading = false;
		}
	}

	showNameByEventType() {
		switch (this.eventType) {
			case "chat:search":
				this.modalTitle = "Encontre um cliente para iniciar uma conversa";
				break;
			case "chat:relate":
				this.modalTitle = "Encontre um cliente para relacionar a uma conversa";
				break;
			case "client:find":
				this.modalTitle = "Encontre um cliente para escolher uma solução";
				break;

			default:
				this.modalTitle = "Encontre um cliente";
				break;
		}
	}
}
