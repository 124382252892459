























































































































































import { ISolution, SubcategoryTypeTranslation, SubcategoryTypeVisualText } from "@/interfaces/solution";
import { IUser } from "@/interfaces/user";
import { Component, Mixins, Prop } from "vue-property-decorator";
import Checkbox from "./Checkbox.vue";
import GuideBalloon from "./GuideBalloon.vue";
import BaseSolutionMixin from "../mixins/base_solution.vue";
import SolutionAvailabilitySelectionModal from "./SolutionAvailabilitySelectionModal.vue";
import { IQualification } from "@/interfaces/qualification";
import { AppointmentModality, IAppointment } from "@/interfaces/appointment";
import { SolutionSelection } from "@/views/promotersPortal/CustomerProfile.vue";
import { BSpinner } from "bootstrap-vue";
import { readableDay } from "@/helpers/calendar";
import CustomButton from "./Button.vue";
import { format } from "date-fns";
import { IAvailability } from "@/interfaces/availability";
import { secondaryBody } from "@/helpers/styleClassHelpers";

@Component({
	components: {
		SolutionAvailabilitySelectionModal,
		GuideBalloon,
		Checkbox,
		CustomButton,
		BSpinner,
	},
})
export default class SolutionCard extends Mixins(BaseSolutionMixin) {
	@Prop({ default: false }) isInAppointmentsModalView!: boolean;
	@Prop() user!: IUser;
	@Prop() header!: any;

	subtitle: any = "";
	isNotSelected = true;
	examOrDoctorName = "-";
	visualData = "Nenhuma informação especificada.";
	qualifications: IQualification[] = [];
	isLoadingPreScheduleRemoval = false;

	secondaryBody = secondaryBody;

	//from database
	baseIcons = ["parking", "accessibility", "househould-visit", "domestic-collection", "telemedicine", "presential"];

	isAvailabilitySelectionModalOpen = false;

	readableDay(availability: IAvailability, solutionCategoryName: string) {
		if (!availability) {
			return "";
		}
		return readableDay(format(new Date(availability.startDateTime), "yyyy-MM-dd"));
	}

	readableSelectedTime(availability: IAvailability, shouldIncludeEndDateTime = true) {
		if (!availability) {
			return;
		}
		const { startDateTime: start, endDateTime: end } = availability;
		return `${format(new Date(start), "HH:mm")}${
			shouldIncludeEndDateTime ? ` às ${format(new Date(end), "HH:mm")}` : ""
		}`;
	}

	created() {
		this.qualifications = (this.solution.solutionQualifications ?? []).map(({ qualification }) => qualification);
		if (this.solution.distanceInKm && this.solution.category.name !== "Orientações") {
			const distance = `${this.solution.distanceInKm.toFixed(1)} km`;
			this.qualifications.unshift({ id: "distance-in-km", name: distance });
		}

		this.isNotSelected = !this.isInAppointmentsModalView;

		if (!this.solution.notFoundView) {
			const dataBasedOnSolutionFieldsHandler: Partial<Record<keyof ISolution, any>> = {
				subcategory: () => {
					const subcategory = this.solution.subcategory;
					this.subtitle = SubcategoryTypeTranslation[subcategory] ?? "";
					this.visualData = this.isInAppointmentsModalView
						? SubcategoryTypeTranslation[subcategory]
						: SubcategoryTypeVisualText[subcategory];
				},
				address: () => {
					this.visualData = this.formatAddress(this.solution.address);
				},
				description: () => {
					this.visualData = this.solution.description;
				},
			};
			const solutionFieldsHandler = Object.keys(dataBasedOnSolutionFieldsHandler);
			const type = solutionFieldsHandler.find(key => this.solution[<keyof ISolution>key]);
			dataBasedOnSolutionFieldsHandler[<keyof ISolution>type]?.();
			this.examOrDoctorName = this.solution.examOrDoctorName;
		}
		this.subtitle = this.isInAppointmentsModalView ? this.solution.category.name : this.subtitle;
	}

	get canOpenAvailabilityModal() {
		return this.canBeScheduled && !this.isNotSelected && !this.isInAppointmentsModalView;
	}

	get relatedAppointment(): Partial<IAppointment> {
		return this.solution.appointment ?? {};
	}
	get relatedAvailability() {
		return this.relatedAppointment?.scheduling?.availability;
	}
	get canBeScheduled() {
		return this.solution?.category?.isAvailabilityInputActive;
	}

	async handleCheckboxSelection(_: any, isCurrentlySelected: boolean) {
		if (this.isInAppointmentsModalView) {
			//unselect solution
			this.$emit("handle-solution-selection", {
				solution: this.solution,
				isSelected: false,
				replacesAnyway: true,
			} as SolutionSelection);
		} else {
			if (this.canBeScheduled && this.isNotSelected) {
				this.isAvailabilitySelectionModalOpen = true;
			} else {
				if (this.canBeScheduled && !isCurrentlySelected) {
					(<any>this.$refs.availabilityModal).isConfirmed = false;
				}
				this.selectSolution({ isSelected: isCurrentlySelected, solution: this.solution });
			}
		}
	}

	selectSolution(selectionData: SolutionSelection) {
		this.isNotSelected = !selectionData.isSelected;
		this.$emit("handle-solution-selection", selectionData);
	}

	openFileModal() {
		this.$emit("open-file-modal", { name: this.solution.name, url: this.solution.attachmentUrl });
	}

	onAppointmentUpdate({ scheduling, ...appointment }: Partial<IAppointment>) {
		Object.assign(this.solution.appointment, appointment);
		if (this.solution.appointment?.scheduling) {
			Object.assign(this.solution.appointment.scheduling, scheduling);
		}
		this.isAvailabilitySelectionModalOpen = false;
	}

	openAttachment(url: string) {
		window.open(url, "_blank");
	}
}
