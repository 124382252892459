import { CrudModel } from "./_crud";
import SolutionAppModel from "./solution_app.model";
import InsurerPlanAppModel from "./insurer_plan_app.model";

export class SolutionInsurerPlanAppModel extends CrudModel {
	constructor() {
		super(
			"app/solutionInsurerPlan",
			[],
			[
				{ fieldKey: "solution", model: SolutionAppModel },
				{ fieldKey: "insurerPlan", model: InsurerPlanAppModel },
			],
		);
	}
}

export default new SolutionInsurerPlanAppModel();
