








































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { primaryBody, secondaryBody, tertiaryBoldBody } from "@/helpers/styleClassHelpers";
import Button from "@/layouts/components/Button.vue";
import Checkbox from "@/layouts/components/Checkbox.vue";
import InitialsBadge from "@/layouts/components/InitialsBadge.vue";
import UserCard from "@/layouts/components/UserCard.vue";
import BaseModal from "@/layouts/components/BaseModal.vue";
import VueSelect from "vue-select";
import SolutionCard from "@/layouts/components/SolutionCard.vue";
import DropdownSection from "@/layouts/components/DropdownSection.vue";
import GuideBalloon from "@/layouts/components/GuideBalloon.vue";
import SolutionFilterModal from "@/layouts/components/SolutionFilterModal.vue";
import AppointmentsConfirmationModal from "@/layouts/components/AppointmentsConfirmationModal.vue";
import SolutionConversationContextModal from "@/layouts/components/SolutionConversationContextModal.vue";
import SolutionCardFileModal from "@/layouts/components/SolutionCardFileModal.vue";
import EntityForm from "../../views/entity/EntityForm.vue";
import Guides from "@/layouts/components/Guides.vue";
import ActionPlan from "@/layouts/components/ActionPlan.vue";
import AppointmentsHistory from "@/layouts/components/AppointmentsHistory.vue";
import CustomTabs from "@/layouts/components/CustomTabs.vue";
import SolutionAppointmentAppModel from "@/api/solution_appointment_app.model";
import CorporateClientAppModel from "@/api/corporate_client_app.model";
import InsurerPlanAppModel from "@/api/insurer_plan_app.model";
import clientAppModel from "@/api/client_app.model";
import NeedAppModel from "../../api/need_app.model";
import diseaseApp from "../../api/disease_app.model";
import enterpriseApp from "../../api/enterprise_app.model";
import treatmentApp from "../../api/treatment_app.model";
import debounce from "debounce";
import { crudDelete, crudGet } from "@/api/_request";
import { ISolution } from "@/interfaces/solution";
import { BSpinner, BOverlay } from "bootstrap-vue";
import { showErrorAlert, showSuccessAlert } from "@/helpers";
import { formatDayMonthYear, isValidCPF, normalize } from "@/helpers/utils";
import { translateGender } from "@/interfaces/user";
import { translateServicePlanKind } from "@/interfaces/service_plan";
import { translateInsurerPlanRelationKind } from "@/interfaces/corporate_client";
import { ICategory } from "@/interfaces/solution_category";
import { INeed } from "@/interfaces/need";
import { DeepPartial } from "@/entities/base_entity";
import { IEnterprise } from "@/interfaces/enterprise";
import tag from "../../api/tag_app.model";
import userApp from "../../api/user_app.model";
import { QueryParams } from "../../api/_crud";

const NO_NEED_CATEGORY_NAME = "Sem necessidade";
const PRE_SELECTED_SECTION_ID = "pre-selected-section";
const BENEFITS_SECTION_ID = "benefits-section";
let categories: ICategory[] = [];

export interface SolutionSelection {
	solution: ISolution;
	isSelected: boolean;
	replacesAnyway?: boolean;
	shouldPlaceToPreSelectedSection?: boolean;
}

@Component({
	components: {
		SolutionFilterModal,
		SolutionCardFileModal,
		AppointmentsConfirmationModal,
		SolutionConversationContextModal,
		UserCard,
		BSpinner,
		GuideBalloon,
		SolutionCard,
		DropdownSection,
		VueSelect,
		InitialsBadge,
		Button,
		Checkbox,
		BaseModal,
		EntityForm,
		BOverlay,
		Guides,
	},
})
export default class CustomerProfile extends Vue {
	debouncedSearch = debounce(this.changeTreatment, 300);

	constructor() {
		super();
		this.debouncedSearch = debounce(this.changeTreatment, 300);
	}

	@Prop() clientId!: string;
	@Prop({ default: "left" }) sideToShow!: "left" | "right";

	tertiaryBoldBody = tertiaryBoldBody;
	secondaryBody = secondaryBody;
	primaryBody = primaryBody;

	clientAppModel = clientAppModel;
	currentComponent = CustomTabs as any;
	currentProps = {} as any;
	currentTitle = "";
	currentButtons = [] as any;
	client = {} as any;
	corporateClientId = "";
	currentTag = "";
	currentTreatment = "";
	initialPrimaryData = {} as any;

	modalIsOpen = false;
	isEntityLoading = false;

	enterpriseSearchParam: string | undefined;
	debouncedEnterpriseSearch = debounce(this.searchEnterprise, 300);
	debouncedTagSearch = debounce(this.changeTag, 300);

	buttons = [
		{ icon: "primary_data.svg", variant: "tertiary", buttonAction: this.openModal.bind(null, "primary") },
		{ icon: "secondary_data.svg", variant: "tertiary", buttonAction: this.openModal.bind(null, "secondary") },
	];

	guides = [
		{
			name: "Contexto Social e Clínico",
			helpText: "Resumo do ambiente familiar e das situações de saúde vivenciadas pelo cliente",
			editIcon: true,
			displayingHelp: false,
			isOpen: false,
			component: EntityForm,
			onClick: this.unlockEditionGuide,
			props: {
				model: clientAppModel,
				shouldFullReload: false,
				formColumns: [{ key: "socialContext", name: "", inputKind: "textarea" }],
				withBackButton: false,
				isReadOnlyInitialValue: true,
			},
		},
		{
			name: "Plano de ação",
			totalData: 0,
			helpText:
				"Lista de soluções ofertadas para atender a necessidade do cliente e que estão pendentes de realização e de avaliação",
			displayingHelp: false,
			isOpen: false,
			showTotal: true,
			component: ActionPlan,
			props: {
				appointments: [] as any,
				onAction: this.appointmentActions,
			},
		},
		{
			name: "Avaliação do paciente pós orientação",
			totalData: 0,
			helpText: "Lista de soluções orientadas a necessidade do cliente e que estão pendentes de avaliação",
			displayingHelp: false,
			isOpen: false,
			showTotal: true,
			component: ActionPlan,
			props: {
				appointments: [] as any,
				onAction: this.appointmentActions,
			},
		},
		{
			name: "Jornada de cuidado",
			totalData: 0,
			helpText:
				"Descritivos das soluções anteriores já ofertadas para o cliente e avaliação da satisfação do cliente em relação a solução proposta",
			displayingHelp: false,
			isOpen: false,
			showTotal: true,
			component: AppointmentsHistory,
			props: {
				appointments: [] as any,
			},
		},
	];

	categoriesLiteral: DeepPartial<Record<string, ICategory>> = {
		[PRE_SELECTED_SECTION_ID]: {
			name: "Soluções pré-agendadas",
		},
		[BENEFITS_SECTION_ID]: {
			name: "Benefícios",
		},
	};
	availableDefaultSectionsIds: string[] = [];
	qualificationOptions: any[] = [];
	needOptions: INeed[] = [];
	//options extended by its synonyms
	extendedNeedOptionsLiteral: Record<string, INeed> = {};
	relatedNeed: INeed | null = null;
	hasAnyNeed = true;
	defaultFilters = {
		maxKmDistance: 9999,
		isFavorite: null,
	};

	conversationContextModal = {
		isOpen: false,
		text: "",
		isLoading: false,
	};
	solutionCardFileModal = {
		isOpen: false,
		name: "",
		url: "",
	};
	appointmentsConfirmationModal = {
		isOpen: false,
		isCreationType: false,
	};

	isSolutionsSearchLoading = false;

	hasAnySection = false;
	solutionSectionsLiteral: Record<string, ISolution[]> = {};

	selectedSolutions: Record<string, ISolution> = {};
	selectedSolutionsArray: ISolution[] = [];

	isSearchModalOpen: boolean = true;

	clientHeader = [
		{ key: "name", kind: "userName" },
		{ key: "lastName", kind: "userName" },
		{
			content: [
				{ key: "birthDate", kind: "age" },
				{
					key: "gender",
					kind: "status",
				},
				{
					key: "corporateClient.name",
				},
				{ key: "isActive", kind: "isActive" },
			],
		},
	];

	formColumnsMainData: any = [
		{ key: "name", name: "Nome", required: true },
		{ key: "lastName", name: "Sobrenome", required: true },
		{ key: "birthDate", readOnly: false, required: true, onTransform: formatDayMonthYear },
		{ key: "gender", onTransform: translateGender },
		{ key: "insurerNumber", kind: "text" },
		{ key: "phoneNumber" },
		{ key: "cpf", required: true, validationField: isValidCPF, messageError: "CPF inválido" },
		{ key: "email" },
		{
			key: "address.postCode",
			autoComplete: true,
			autoCompleteKeys: ["address.street", "address.neighborhood", "address.city", "address.state"],
		},
		"address.street",
		"address.number",
		"address.complement",
		"address.neighborhood",
		"address.city",
		"address.state",
		{
			key: "loyalUser.id",
			name: "Fidelizador",
			kind: "select",
			options: [{}],
			showFullName: true,
		},
		{
			key: "corporateClient.id",
			name: "Cliente corporativo",
			required: true,
			kind: "select",
			options: [],
		},
		{
			key: "insurerPlan.id",
			name: "Plano",
			required: true,
			kind: "select",
			options: [],
			validationToShow: (entity: any) => {
				return entity["corporateClient.id"] !== "";
			},
		},
	];

	formColumnsDataNavigation: any = [
		"responsibleNavigationName",
		"responsibleMedicalTeamName",
		"treatmentForm",
		"empathy",
		{ key: "solutionStartDate", name: "Data de início" },
		{
			key: "isActive",
			inputKind: "selectButtons",
			options: [
				{ name: "Sim", value: true },
				{ name: "Não", value: false },
			],
		},
		{
			key: "antecedentDiseases",
			kind: "multiselect",
			relationKey: "disease",
			formatEntity: true,
			onTransform: this.formatReadOnlyDisease,
			options: [{}],
			shouldAddPushTagsClass: true,
		},
		{
			key: "clientTreatments",
			kind: "multiselect",
			relationKey: "treatment",
			formatEntity: true,
			shouldDisplayAnyway: true,
			onTransform: this.formatReadOnlyTreatment,
			onSearch: this.debouncedSearch,
			options: [{}],
			shouldAddPushTagsClass: true,
		},
		{
			key: "clientTags",
			kind: "multiselect",
			emptyValue: [],
			relationKey: "tag",
			formatEntity: true,
			shouldDisplayAnyway: true,
			onTransform: this.formatReadOnlyTag,
			onSearch: this.debouncedTagSearch,
			options: [{}],
		},
		{
			key: "planInfoDependency",
			inputKind: "selectButtons",
			options: [
				{ name: "Sim", value: 1 },
				{ name: "Não", value: 0 },
			],
		},
	];

	formColumnsSecondaryData = [
		"secondEmail",
		"secondPhoneNumber",
		"serviceDeliveryAddress",
		"isSecondaryAddressMain",
		{
			key: "secondaryAddress.postCode",
			autoComplete: true,
			autoCompleteKeys: [
				"secondaryAddress.street",
				"secondaryAddress.neighborhood",
				"secondaryAddress.city",
				"secondaryAddress.state",
			],
		},
		"secondaryAddress.street",
		"secondaryAddress.number",
		"secondaryAddress.complement",
		"secondaryAddress.neighborhood",
		"secondaryAddress.city",
		"secondaryAddress.state",
	];

	formColumnsCaregiver = ["caregiver", "contactPhoneNumber", "caregiverBond"];

	formColumnsInsurerPlanInformation = [
		{ key: "insurerPlan.name", name: "Nome do plano", isReadOnly: true },
		{
			key: "insurerPlan.emergencyService",
			inputKind: "selectButtons",
			isReadOnly: true,
			options: [
				{ name: "Sim", value: true },
				{ name: "Não", value: false },
			],
		},
		{ key: "insurerPlan.emergencyServiceNumber", isReadOnly: true },
		{
			key: "insurerPlan.servicePlans",
			name: "Serviços",
			kind: "customForm",
			onTransform: this.formatReadOnlyServices,
			isReadOnly: true,
		},
		{
			key: "insurerPlanRelationKind",
			inputKind: "selectButtons",
			onTransform: translateInsurerPlanRelationKind,
		},
		"restriction",
		"homeCare",
		"proceduresCharge",
		{
			key: "enterprise.id",
			name: "Empresa",
			required: false,
			kind: "select",
			options: [{}],
			onSearch: this.debouncedEnterpriseSearch,
		},
	];

	tabsSecondaryDataColumns = [
		...this.formColumnsDataNavigation,
		...this.formColumnsSecondaryData,
		...this.formColumnsCaregiver,
		...this.formColumnsInsurerPlanInformation,
	];

	tabsSecondaryCleanFormKeys = {
		deleteKeys: [
			"id",
			"insurerPlan.name",
			"insurerPlan.emergencyService",
			"insurerPlan.emergencyServiceNumber",
			"insurerPlan.servicePlans",
		],
	};

	get tabsSecondaryData() {
		return [
			{
				name: "Dados de Navegação",
				component: EntityForm,
				props: {
					idRead: this.clientId,
					model: clientAppModel,
					secondaryFormColumns: this.tabsSecondaryDataColumns,
					isSecondaryFormColumnsToSend: true,
					cleanFormKeys: this.tabsSecondaryCleanFormKeys,
					shouldFullReload: false,
					formColumns: this.formColumnsDataNavigation,
					withBackButton: false,
					isReadOnlyInitialValue: true,
				},
			},
			{
				name: "Outros Contatos",
				component: EntityForm,
				props: {
					idRead: this.clientId,
					model: clientAppModel,
					secondaryFormColumns: this.tabsSecondaryDataColumns,
					isSecondaryFormColumnsToSend: true,
					cleanFormKeys: this.tabsSecondaryCleanFormKeys,
					shouldFullReload: false,
					formColumns: this.formColumnsSecondaryData,
					withBackButton: false,
					isReadOnlyInitialValue: true,
					withPostalCode: true,
				},
			},
			{
				name: "Suporte social",
				component: EntityForm,
				props: {
					idRead: this.clientId,
					model: clientAppModel,
					secondaryFormColumns: this.tabsSecondaryDataColumns,
					isSecondaryFormColumnsToSend: true,
					cleanFormKeys: this.tabsSecondaryCleanFormKeys,
					shouldFullReload: false,
					formColumns: this.formColumnsCaregiver,
					withBackButton: false,
					isReadOnlyInitialValue: true,
				},
			},
			{
				name: "Informações do Plano",
				component: EntityForm,
				props: {
					model: clientAppModel,
					secondaryFormColumns: this.tabsSecondaryDataColumns,
					isSecondaryFormColumnsToSend: true,
					cleanFormKeys: this.tabsSecondaryCleanFormKeys,
					idRead: this.clientId,
					shouldFullReload: false,
					formColumns: this.formColumnsInsurerPlanInformation,
					withBackButton: false,
					isReadOnlyInitialValue: true,
				},
			},
		];
	}

	async created() {
		const { data: needs } = await NeedAppModel.get("", { page: 1, limit: 0 });
		this.needOptions = needs;
		this.qualificationOptions = (await crudGet("app", "qualification")) ?? [];
		this.needOptions.forEach(need => {
			this.extendedNeedOptionsLiteral[need.name] = need;
			need.synonyms?.forEach(synonym => {
				this.extendedNeedOptionsLiteral[synonym] = {
					...need,
					name: `${synonym} (${need.name})`,
				};
			});
		});
		categories = await crudGet("app", "category");
		categories.forEach(category => {
			this.categoriesLiteral[category.id] = category;
		});

		const preSelectedSolutions = await crudGet("app/solution", "bulk/preSelected", {
			clientId: this.clientId,
		});
		this.solutionSectionsLiteral = {
			...(preSelectedSolutions.length ? { [PRE_SELECTED_SECTION_ID]: preSelectedSolutions } : {}),
		};
		this.setWhetherItHasAnySection();
		this.prepareNewSections(preSelectedSolutions);
	}

	mounted() {
		this.readClient();
		this.getAppointments();
		this.getRelationFields();
	}

	filterNeeds(options: INeed[], search: string): INeed[] {
		const normalizedSearch = normalize(search);
		return options.filter(option => normalize(option.name).includes(normalizedSearch));
	}

	changeTreatment(value: string) {
		this.currentTreatment = value;
	}

	changeTag(value: string) {
		this.currentTag = value;
	}

	@Watch("currentTreatment")
	@Watch("currentTag")
	handleSearch() {
		this.getRelationFields();
	}

	formatReadOnlyDisease(diseases: any[]) {
		if (!diseases || !diseases.length) {
			return;
		}
		const formatDiseases = diseases?.reduce((stringDiseases, currentDisease, indexDisease) => {
			return `${stringDiseases} ${currentDisease.disease?.description}${
				indexDisease !== diseases.length - 1 && diseases.length > 1 ? "," : ""
			}`;
		}, "");

		return formatDiseases;
	}

	formatReadOnlyTreatment(treatments: any[]) {
		if (!treatments || !treatments.length) {
			return;
		}
		const formatTreatments = treatments?.reduce((stringTreatments, currentTreatment, indexTreatment) => {
			return `${stringTreatments} ${currentTreatment.treatment?.productName}${
				indexTreatment !== treatments.length - 1 && treatments.length > 1 ? "," : ""
			}`;
		}, "");

		return formatTreatments;
	}

	formatReadOnlyTag(tags: any[]) {
		if (!tags || !tags.length) {
			return;
		}

		const formatTags = tags?.reduce((stringTag, currentTag, indexTag) => {
			return `${stringTag} ${currentTag.tag?.name}${indexTag !== tags.length - 1 && tags.length > 1 ? "," : ""}`;
		}, "");

		return formatTags;
	}

	formatReadOnlyServices(services: any[]) {
		if (!services || !services.length) {
			return;
		}
		return services?.reduce((stringServices, currentService, indexService) => {
			return `${stringServices} ${translateServicePlanKind(currentService.serviceKind)}${
				currentService.otherServiceKind ? " - " + currentService.otherServiceKind : ""
			} - ${currentService.description}${indexService !== services.length - 1 && services.length > 1 ? "<br>" : ""}`;
		}, "");
	}

	unlockEditionGuide() {
		this.toggleLockEdition(this.guides, false);
	}

	unlockEdition() {
		this.toggleLockEdition(this.tabsSecondaryData, false);
	}

	toggleUnlockEditionMainTab() {
		this.currentProps.isReadOnlyInitialValue = !this.currentProps.isReadOnlyInitialValue;
	}

	toggleLockEdition(tabs: any[], status: boolean) {
		tabs.forEach(tab => {
			if (tab.props) {
				tab.props.isReadOnlyInitialValue = status;
			}
		});
	}

	appointmentActions(action: string, value: any, appointment: any) {
		switch (action) {
			case "approved":
				appointment.ratingValue = value;
				this.approvedAppointment(appointment);
				break;
			case "refused":
				appointment.reason = value.reason;
				appointment.description = value.description;
				this.refusedAppointment(appointment);
				break;
			case "updateReturnDate":
				appointment.returnDate = value.returnDate;
				this.updateAppointmentReturnDate(appointment);
				break;
			default:
				break;
		}
	}

	async getAppointments() {
		const appointments = await SolutionAppointmentAppModel.getAppointments({
			limit: 0,
			page: 1,
			clientId: this.clientId,
			shouldOrderByAppointmentCreatedDate: true,
			shouldGetWithoutDateFilter: true,
		});

		const guidePlanAction = this.guides.find(guide => guide.name === "Plano de ação");
		const guideClientReview = this.guides.find(guide => guide.name === "Avaliação do paciente pós orientação");
		const guideAppointmentsHistory = this.guides.find(guide => guide.name === "Jornada de cuidado");

		guidePlanAction!.props.appointments = [];
		guideClientReview!.props.appointments = [];
		guideAppointmentsHistory!.props.appointments = [];

		appointments.data.forEach((appointment: any) => {
			if (
				["Orientações", "Sem necessidade"].includes(appointment.solution.category.name) &&
				appointment.status === "CONFIRMED"
			) {
				guideClientReview?.props.appointments.push(appointment);
			} else if (appointment.status === "CONFIRMED") {
				guidePlanAction?.props.appointments.push(appointment);
			} else if (appointment.status !== "PRE_SELECTED") {
				guideAppointmentsHistory?.props.appointments.push(appointment);
			}
		});

		guidePlanAction!.totalData = guidePlanAction?.props.appointments.length;
		guideClientReview!.totalData = guideClientReview?.props.appointments.length;
		guideAppointmentsHistory!.totalData = guideAppointmentsHistory?.props.appointments.length;
	}

	async approvedAppointment(appointment: any) {
		this.isEntityLoading = true;

		try {
			await SolutionAppointmentAppModel.patch(appointment.id, "complete", {
				ratingValue: appointment.ratingValue,
			});

			await this.getAppointments();

			showSuccessAlert("Os dados foram registrados.");
		} catch (error: any) {
			const errorMessage: string | undefined = error?.friendlyMessage ?? error?.message;
			showErrorAlert(errorMessage || "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isEntityLoading = false;
		}
	}

	async refusedAppointment(appointment: any) {
		this.isEntityLoading = true;

		try {
			await SolutionAppointmentAppModel.patch(appointment.id, "fail", {
				reason: appointment.reason,

				description: appointment.description,
			});

			await this.getAppointments();

			showSuccessAlert("Os dados foram registrados.");
		} catch (error: any) {
			const errorMessage: string | undefined = error?.friendlyMessage ?? error?.message;
			showErrorAlert(errorMessage || "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isEntityLoading = false;
		}
	}

	async updateAppointmentReturnDate(appointment: any) {
		this.isEntityLoading = true;

		try {
			await SolutionAppointmentAppModel.patch(appointment.id, "updateReturnDate", {
				returnDate: appointment.returnDate,
			});

			await this.getAppointments();

			showSuccessAlert("Os dados foram registrados.");
		} catch (error: any) {
			const errorMessage: string | undefined = error?.friendlyMessage ?? error?.message;
			showErrorAlert(errorMessage || "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isEntityLoading = false;
		}
	}

	async readClient() {
		this.isEntityLoading = true;

		try {
			const response = await clientAppModel.read(this.clientId);
			this.client = response;
			const foundGuide = this.guides.find(guide => guide.name === "Contexto Social e Clínico");
			if (foundGuide) {
				Object.assign(foundGuide.props, { idRead: response.id });
			}
		} catch (error) {
			showErrorAlert((error as any)?.friendlyMessage || "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isEntityLoading = false;
		}
	}

	async searchEnterprise(value: string) {
		this.enterpriseSearchParam = value;
		const enterprises = await enterpriseApp.search({ page: 1, limit: 50, searchParam: value });

		const enterpriseField: any = this.formColumnsInsurerPlanInformation!.find(
			(field: any) => field.key === "enterprise.id",
		);

		if (enterpriseField) {
			enterprises.data.forEach((enterprise: IEnterprise) => {
				if (!enterpriseField.options.find((enterpriseOption: any) => enterpriseOption.name === enterprise.name)) {
					enterpriseField.options.push({ name: enterprise.name, value: enterprise.id });
				}
			});
		}
	}

	@Watch("corporateClientId")
	async getRelationFields() {
		this.isEntityLoading = true;
		try {
			const { data: corporateClients } = await CorporateClientAppModel.getParam({ page: 1, limit: 0 });
			const { data: insurerPlans } = await InsurerPlanAppModel.getParam({
				page: 1,
				limit: 0,
				corporateClientIds: this.corporateClientId ? [this.corporateClientId] : [],
			});

			let treatmentParams: QueryParams = {
				page: 1,
				limit: 10,
			};

			let tagsParams: QueryParams = {
				page: 1,
				limit: 50,
			};

			if (this.currentTreatment) {
				treatmentParams.searchParam = this.currentTreatment;
			}

			if (this.currentTag) {
				tagsParams.searchParam = this.currentTreatment;
			}

			const treatments = await treatmentApp.getParam(treatmentParams);
			const tags = await tag.search(tagsParams);

			const diseases = await diseaseApp.getParam({
				page: 1,
				limit: 0,
			});

			const users = await userApp.getAllHealthPromoters({
				page: 1,
				limit: 0,
			});

			if (!this.formColumnsDataNavigation.filter((field: any) => field.key === "antecedentDiseases").length) {
				this.formColumnsDataNavigation.unshift({
					key: "antecedentDiseases",
					kind: "multiselect",
					relationKey: "disease",
					formatEntity: true,
					onTransform: this.formatReadOnlyDisease,
					options: diseases.data.map((disease: any) => {
						return {
							name: disease.description,
							value: { disease: { id: disease.id, description: disease.description } },
						};
					}),
				});
			} else {
				this.formColumnsDataNavigation.find((field: any) => field.key === "antecedentDiseases")!.options =
					diseases.data.map((disease: any) => {
						return {
							name: disease.description,
							value: { disease: { id: disease.id, description: disease.description } },
						};
					});
			}

			if (!this.formColumnsDataNavigation.filter((field: any) => field.key === "clientTreatments").length) {
				this.formColumnsDataNavigation.unshift({
					key: "clientTreatments",
					kind: "multiselect",
					relationKey: "treatment",
					formatEntity: true,
					shouldDisplayAnyway: true,
					onTransform: this.formatReadOnlyTreatment,
					options: treatments.data.map((treatment: any) => {
						return {
							name: `${treatment.productName} - ${treatment.substanceName}`,
							value: {
								treatment: {
									id: treatment.id,
									productName: treatment.productName,
									substanceName: treatment.substanceName,
								},
							},
						};
					}),
					onSearch: this.debouncedSearch,
				});
			} else {
				this.formColumnsDataNavigation.find((field: any) => field.key === "clientTreatments")!.options =
					treatments.data.map((treatment: any) => {
						return {
							name: `${treatment.productName} - ${treatment.substanceName}`,
							value: {
								treatment: {
									id: treatment.id,
									productName: treatment.productName,
									substanceName: treatment.substanceName,
								},
							},
						};
					});
			}

			if (!this.formColumnsDataNavigation.filter((field: any) => field.key === "clientTags").length) {
				this.formColumnsDataNavigation.unshift({
					key: "clientTags",
					kind: "multiselect",
					relationKey: "tag",
					formatEntity: true,
					shouldDisplayAnyway: true,
					onTransform: this.formatReadOnlyTag,
					options: tags.data.map((tag: any) => {
						return {
							name: tag.name,
							value: { tag: { id: tag.id, name: tag.name } },
						};
					}),
					onSearch: this.debouncedTagSearch,
				});
			} else {
				this.formColumnsDataNavigation.find((field: any) => field.key === "clientTags").options = tags.data.map(
					(tag: any) => {
						return {
							name: tag.name,
							value: { tag: { id: tag.id, name: tag.name } },
						};
					},
				);
			}

			this.currentProps.formColumns = [
				{ key: "name", name: "Nome", required: true },
				{ key: "lastName", name: "Sobrenome", required: true },
				{ key: "birthDate", readOnly: false, required: true, onTransform: formatDayMonthYear },
				{ key: "gender", onTransform: translateGender },
				{ key: "insurerNumber", kind: "text" },
				{ key: "phoneNumber" },
				{ key: "cpf", required: true, validationField: isValidCPF, messageError: "CPF inválido" },
				{ key: "email" },
				{
					key: "address.postCode",
					autoComplete: true,
					autoCompleteKeys: ["address.street", "address.neighborhood", "address.city", "address.state"],
				},
				"address.street",
				"address.number",
				"address.complement",
				"address.neighborhood",
				"address.city",
				"address.state",
				{
					key: "loyalUser.id",
					name: "Fidelizador",
					kind: "select",
					showFullName: true,
					options: users.data.map((user: any) => {
						return { name: `${user.name}${user.lastName ? " " + user.lastName : ""}`, value: user.id };
					}),
				},
				{
					key: "corporateClient.id",
					name: "Cliente corporativo",
					required: true,
					kind: "select",
					options: corporateClients.map((corporateClient: any) => {
						return { name: corporateClient.name, value: corporateClient.id };
					}),
				},
				{
					key: "insurerPlan.id",
					name: "Plano",
					required: true,
					modifyValue: true,
					kind: "select",
					options: insurerPlans.map((insurerPlan: any) => {
						return { name: insurerPlan.name, value: insurerPlan.id };
					}),
					validationToShow: (entity: any) => {
						return entity["corporateClient.id"] !== "";
					},
				},
			];
		} catch (error) {
			console.error(error);
		} finally {
			this.isEntityLoading = false;
		}
	}

	setConversationContext(text: string) {
		this.conversationContextModal.text = text;
		const solutionCardsRefs = (this.$refs.solutionCards ?? []) as SolutionCard[];
		solutionCardsRefs.forEach(ref => (ref.solution.conversationContext = text));
	}

	clearConversationContext() {
		const conversationContextModalRef = this.$refs.conversationContextModalRef as SolutionConversationContextModal;
		conversationContextModalRef.localText = "";
		this.conversationContextModal.text = "";
	}

	openFilterModal(categoryId: string) {
		this.solutionSectionsLiteral[categoryId][0].isFilterModalOpen = true;
		//CHECK how it should be done
		//deep copy for <DropdownSection /> update, and consequently its children
		this.reRenderSections();
	}

	async removeFiltersAndSearch(categoryId: string) {
		const modals = this.$refs.filterModals as SolutionFilterModal[];
		const modal = modals.find(({ category }) => category.id === categoryId)!;
		this.solutionSectionsLiteral[categoryId][0].isRemovingFilters = true;
		this.reRenderSections();
		modal.removeFilters();
		await modal.filterSolutionsSection();
		this.solutionSectionsLiteral[categoryId][0].isRemovingFilters = false;
	}

	reRenderSections() {
		this.solutionSectionsLiteral = JSON.parse(JSON.stringify(this.solutionSectionsLiteral));
	}

	openAppointmentsModal() {
		this.appointmentsConfirmationModal = { isOpen: true, isCreationType: true };
	}

	async handleNoNeedCase() {
		if (!this.hasAnyNeed) {
			try {
				this.isSolutionsSearchLoading = true;
				const { solutionsSectionedByCategories: sections } = await crudGet("app", "solution", {
					shouldSelectOnlyWithNeeds: false,
					category: { name: NO_NEED_CATEGORY_NAME },
					page: 1,
					limit: 0,
					clientId: this.clientId,
				});
				this.resetAll();
				if (Object.keys(sections).length) {
					const categoryId = Object.keys(sections)[0];
					this.solutionSectionsLiteral[categoryId] = sections?.[categoryId] ?? { notFoundView: true };
					this.setWhetherItHasAnySection();
				}
			} catch (e) {
				//
			} finally {
				this.isSolutionsSearchLoading = false;
			}
		} else {
			this.resetAll();
		}
	}

	openSolutionFileModal({ name, url }: { name: string; url: string }) {
		this.solutionCardFileModal = {
			isOpen: true,
			name,
			url,
		};
	}

	async handleSolutionSelection({
		solution,
		isSelected,
		replacesAnyway = false,
		shouldPlaceToPreSelectedSection = false,
	}: SolutionSelection) {
		if (isSelected && (replacesAnyway || !this.selectedSolutions[solution.id])) {
			this.selectedSolutions[solution.id] = solution;

			if (shouldPlaceToPreSelectedSection) {
				const currentSection = this.solutionSectionsLiteral[solution.category.id];
				const index = currentSection.findIndex(({ id }) => id === solution.id);
				currentSection.splice(index, 1);

				const currentBenefitsSection: ISolution[] | undefined = this.solutionSectionsLiteral[BENEFITS_SECTION_ID];
				const benefitsIndex: number = currentBenefitsSection
					? currentBenefitsSection.findIndex(({ id }) => id === solution.id)
					: -1;
				if (benefitsIndex !== -1) {
					currentBenefitsSection.splice(benefitsIndex, 1);
				}

				if (!currentSection.length) {
					this.solutionSectionsLiteral[solution.category.id] = [{ notFoundView: true } as any];
				}

				if (!currentBenefitsSection?.length) {
					this.solutionSectionsLiteral[BENEFITS_SECTION_ID] = [{ notFoundView: true } as any];
				}

				this.solutionSectionsLiteral[PRE_SELECTED_SECTION_ID] = [
					...(this.solutionSectionsLiteral[PRE_SELECTED_SECTION_ID] ?? []),
					solution,
				];

				const entries = Object.entries(this.solutionSectionsLiteral);
				const preSelectedSectionIndex = entries.findIndex(([key]) => key === PRE_SELECTED_SECTION_ID);
				entries.unshift(entries[preSelectedSectionIndex]);

				this.solutionSectionsLiteral = Object.fromEntries(entries);

				await this.$nextTick();
				const solutionCardsRefs = (this.$refs.solutionCards ?? []) as SolutionCard[];
				const refs = solutionCardsRefs.filter(ref => ref.solution.id === solution.id)!;

				if (refs.length) {
					refs.forEach(ref => (ref.isNotSelected = false));
				}
			}
		} else if (!isSelected && (replacesAnyway || this.selectedSolutions[solution.id])) {
			const solutionCardsRefs = (this.$refs.solutionCards ?? []) as SolutionCard[];
			const refs = solutionCardsRefs.filter(ref => ref.solution.id === solution.id)!;
			//if solution.appointment, it means this solution was pre-selected by the current promotor
			if (solution.appointment) {
				refs.forEach(ref => (ref.isLoadingPreScheduleRemoval = true));
				await crudDelete("app/solutionAppointment", solution.appointment!.id!);
				const currentSection = this.solutionSectionsLiteral[PRE_SELECTED_SECTION_ID];
				const index = currentSection.findIndex(({ id }) => id === solution.id);
				currentSection.splice(index, 1);
				refs.forEach(ref => (ref.solution.appointment = undefined));

				if (!currentSection.length) {
					delete this.solutionSectionsLiteral[PRE_SELECTED_SECTION_ID];
				}

				const desiredSection = this.solutionSectionsLiteral[solution.category.id];
				if (desiredSection) {
					this.solutionSectionsLiteral[solution.category.id] = [
						solution,
						...(desiredSection[0].notFoundView ? [] : desiredSection),
					];
				}
				const benefitSection = this.solutionSectionsLiteral[BENEFITS_SECTION_ID];
				const shouldBelongBenefitsSection = !!solution?.enterpriseSolutions?.find(
					benefit => benefit?.enterprise?.id === this.client?.enterpriseId,
				);
				if (!!benefitSection && shouldBelongBenefitsSection) {
					this.solutionSectionsLiteral[BENEFITS_SECTION_ID] = [
						solution,
						...(benefitSection[0].notFoundView ? [] : benefitSection),
					];
				}
				refs.forEach(ref => (ref.isLoadingPreScheduleRemoval = false));
			}

			if (refs.length) {
				refs.forEach(ref => (ref.isNotSelected = true));
			}
			delete this.selectedSolutions[solution.id];
			this.setWhetherItHasAnySection();
		}
		this.setSelectedSolutionsArray();
		if (!this.selectedSolutionsArray.length) {
			this.appointmentsConfirmationModal.isOpen = false;
		}
	}

	setSelectedSolutionsArray() {
		this.selectedSolutionsArray = Object.values(this.selectedSolutions);
	}

	onAppointmentsConfirmationModalClose(shouldClearTheSearch: boolean) {
		this.appointmentsConfirmationModal.isOpen = false;

		if (shouldClearTheSearch === true) {
			this.resetAll();
		}
	}

	setWhetherItHasAnySection() {
		this.hasAnySection = !!Object.keys(this.solutionSectionsLiteral).length;
	}

	async setFilteredSection(categoryId: string, section: ISolution[]) {
		//TODO set selectedSolutions (whether SolutionCard.vue is checked or not etc)
		if (section.length) {
			this.solutionSectionsLiteral[<string>categoryId] = section;
		} else if (!this.solutionSectionsLiteral[<string>categoryId][0].notFoundView) {
			this.solutionSectionsLiteral[<string>categoryId] = [{ notFoundView: true } as any];
			this.setWhetherItHasAnySection();
		}

		this.solutionSectionsLiteral[categoryId][0].isFilterModalOpen = false;
	}

	resetAll({
		shouldUnselectSolutions = true,
		shouldClearSections = true,
		shouldKeepPreSelected = false,
	}: { shouldUnselectSolutions?: boolean; shouldClearSections?: boolean; shouldKeepPreSelected?: boolean } = {}) {
		if (shouldUnselectSolutions) {
			this.unselectAllSolutions();
		}
		this.relatedNeed = null;
		if (shouldClearSections) {
			if (!shouldKeepPreSelected) {
				delete this.solutionSectionsLiteral[PRE_SELECTED_SECTION_ID];
			}
			const preSelectedSolutions = this.solutionSectionsLiteral[PRE_SELECTED_SECTION_ID];
			this.solutionSectionsLiteral = {
				...(preSelectedSolutions ? { [PRE_SELECTED_SECTION_ID]: preSelectedSolutions } : {}),
			};
			this.setWhetherItHasAnySection();
		}
	}

	async searchSolutions() {
		if (!this.hasAnyNeed) {
			return;
		}

		if (!this.relatedNeed) {
			this.resetAll({ shouldUnselectSolutions: false, shouldKeepPreSelected: true });
			return;
		}

		try {
			this.isSolutionsSearchLoading = true;
			const {
				solutionsSectionedByCategories: sections = {},
				availableCategoriesIds: availableDefaultSectionsIds = [],
				preSelectedSolutions = [],
				benefitsSolutions = [],
			}: {
				solutionsSectionedByCategories: Record<string, ISolution[]>;
				preSelectedSolutions: ISolution[];
				availableCategoriesIds: string[];
				benefitsSolutions: ISolution[];
			} = await crudGet("app", "solution", {
				...(this.relatedNeed ? { needId: this.relatedNeed.id } : {}),
				...this.defaultFilters,
				clientId: this.clientId,
				page: 1,
				limit: 0,
			});

			const sectionsKeys = Object.keys(sections);
			sectionsKeys.forEach(key => {
				const filteredSectionsByHasInsurerPlans = sections[key].filter((section: any) => section.hasInsurerPlans);
				filteredSectionsByHasInsurerPlans.length
					? (sections[key] = filteredSectionsByHasInsurerPlans)
					: delete sections[key];
			});

			this.availableDefaultSectionsIds = availableDefaultSectionsIds;
			this.solutionSectionsLiteral = sections;
			this.solutionSectionsLiteral = {
				...(preSelectedSolutions.length ? { [PRE_SELECTED_SECTION_ID]: preSelectedSolutions } : {}),
				...(benefitsSolutions.length ? { [BENEFITS_SECTION_ID]: benefitsSolutions } : {}),
				...this.defaultSections,
				...this.solutionSectionsLiteral,
			};
			this.setSectionsDefaultValues();
			this.setWhetherItHasAnySection();

			if (this.hasAnySection) {
				this.prepareNewSections(preSelectedSolutions);
			}
		} catch (e) {
			showErrorAlert("Ocorreu um erro. Tente novamente.");
		} finally {
			this.isSolutionsSearchLoading = false;
		}
	}

	async prepareNewSections(solutionsToBeSelected: ISolution[] = []) {
		await this.$nextTick();
		solutionsToBeSelected.forEach(solution => this.handleSolutionSelection({ solution, isSelected: true }));

		//find for a previous selected solution, and it's found. the select ir
		const selectedSolutionsIds = Object.keys(this.selectedSolutions);
		const solutionCardsRefs = (this.$refs.solutionCards ?? []) as SolutionCard[];
		solutionCardsRefs.forEach(ref => {
			Object.assign(ref.solution, {
				relatedNeed: this.relatedNeed,
				...(!ref.solution.appointment ? { conversationContext: this.conversationContextModal.text } : {}),
			});
			if (selectedSolutionsIds.includes(ref.solution.id)) {
				ref.isNotSelected = false;
			}
		});
	}

	get defaultSections() {
		const defaultSections: any = {};
		this.availableDefaultSectionsIds.forEach(categoryId => {
			if (!this.solutionSectionsLiteral[categoryId]) {
				defaultSections[categoryId] = [{ notFoundView: true } as any];
			}
		});

		return defaultSections;
	}

	setSectionsDefaultValues() {
		Object.keys(this.solutionSectionsLiteral).forEach(key => {
			const solutions = this.solutionSectionsLiteral[key];
			const firstSolution = solutions[0];
			if (this.categoriesLiteral[key]) {
				const { isFavorite, maxKmDistance } = this.defaultFilters;
				Object.assign(firstSolution, {
					isFilterModalOpen: false,
					filterVisualData: `Filtros: ${maxKmDistance} km, Rede preferencial: ${isFavorite ? "Sim" : "Não"}`,
				});
			}
		});
	}

	unselectAllSolutions() {
		const solutionCardsRefs = (this.$refs.solutionCards ?? []) as any[];
		solutionCardsRefs.forEach(ref => {
			ref.isNotSelected = true;
			if (ref.solution?.category?.isAvailabilityInputActive) {
				ref.$refs.availabilityModal.isConfirmed = false;
				ref.$refs.availabilityModal.availabilities = null;
				ref.$refs.availabilityModal.selectedAvailabilityIndex = null;
			}
		});
		this.selectedSolutions = {};
		this.setSelectedSolutionsArray();
	}

	goBack() {
		this.$router.back();
	}

	changeSelect(value: any, fieldKey: string) {
		if (fieldKey === "corporateClient.id") {
			this.corporateClientId = value;
		}
	}

	async openModal(buttonClicked: string) {
		switch (buttonClicked) {
			case "primary":
				this.currentComponent = EntityForm;
				this.currentProps = {
					model: clientAppModel,
					idRead: this.clientId,
					shouldFullReload: false,
					formColumns: this.formColumnsMainData,
					withBackButton: false,
					isReadOnlyInitialValue: true,
					onInputChange: this.changeSelect,
					toggleUnlockEdition: this.toggleUnlockEditionMainTab,
					cleanFormKeys: { deleteKeys: ["id"], holdKeys: ["loyalUser.id"] },
					onSuccessfullyLoadingEntity: this.setLocalInitialPrimaryData,
				};
				this.getRelationFields();
				this.currentTitle = "Dados Principais";
				this.currentButtons = [
					{ icon: "copy-icon.svg", variant: "tertiary", buttonAction: this.copyPrimaryData },
					{ icon: "edit-icon.svg", variant: "tertiary", buttonAction: this.toggleUnlockEditionMainTab },
				];
				break;
			case "secondary":
				await this.loadEnterprises();

				this.currentComponent = CustomTabs;
				this.currentProps = {
					tabs: this.tabsSecondaryData,
				};
				this.currentTitle = "Dados Secundários";
				this.currentButtons = [{ icon: "edit-icon.svg", variant: "tertiary", buttonAction: this.unlockEdition }];
				break;
			default:
				break;
		}
		this.modalIsOpen = true;
	}

	reloadComponent() {
		this.modalIsOpen = false;
		this.$emit("onReload");
	}

	async loadEnterprises() {
		const enterprises = await enterpriseApp.search({ page: 1, limit: 50 });

		const enterpriseField = this.tabsSecondaryData
			.find((tab: any) => tab.name === "Informações do Plano")
			?.props.formColumns.find((column: any) => column.key === "enterprise.id");

		const clientEnterpriseId = this.client["enterprise.id"];
		const clientEnterpriseName = this.client["enterprise.name"];
		if (
			clientEnterpriseId &&
			clientEnterpriseName &&
			!enterpriseField.options.find((option: any) => {
				option.name === clientEnterpriseName;
			})
		) {
			enterpriseField.options.push({ name: clientEnterpriseName, value: clientEnterpriseId });
		}

		enterprises.data.forEach((enterprise: IEnterprise) => {
			if (!enterpriseField.options.find((enterpriseOption: any) => enterpriseOption.name === enterprise.name)) {
				enterpriseField.options.push({ name: enterprise.name, value: enterprise.id });
			}
		});
	}

	setLocalInitialPrimaryData(entity: any) {
		this.initialPrimaryData = entity;
		this.corporateClientId = this.initialPrimaryData["corporateClient.id"] || "";
	}

	copyPrimaryData() {
		const headersToCopy = [
			{
				key: "name",
				name: "Nome Completo",
				unionWithKeys: [{ key: "lastName", separator: "" }],
			},
			{ key: "lastName", name: "Sobrenome", shoulndCopy: true },
			{ key: "birthDate", name: "Data de nascimento", onFormat: (birthDate: any) => formatDayMonthYear(birthDate) },
			{ key: "gender", name: "Sexo", onFormat: (gender: any) => translateGender(gender) },
			{ key: "insurerNumber", name: "Numero da carteira" },
			{ key: "phoneNumber", name: "Telefone com DDD" },
			{ key: "cpf", name: "CPF" },
			{ key: "email", name: "E-mail" },
			{ key: "address.postCode", name: "CEP", shoulndCopy: true },
			{
				key: "address.street",
				name: "Endereço",
				unionWithKeys: [
					{ key: "address.number", separator: ", " },
					{ key: "address.complement", separator: " - " },
					{ key: "address.postCode", separator: " - " },
					{ key: "address.neighborhood", separator: " - " },
					{ key: "address.city", separator: ", " },
					{ key: "address.state", separator: "" },
				],
			},
			{ key: "address.number", name: "Número", shoulndCopy: true },
			{ key: "address.complement", name: "Complemento", shoulndCopy: true },
			{ key: "address.neighborhood", name: "Bairro", shoulndCopy: true },
			{ key: "address.city", name: "Cidade", shoulndCopy: true },
			{ key: "address.state", name: "Estado", shoulndCopy: true },
			{ key: "corporateClient.name", name: "Cliente corporativo" },
			{ key: "insurerPlan.name", name: "Plano" },
		];

		const formattedTexts = headersToCopy.map(header => {
			if (header.shoulndCopy) {
				return;
			} else if (header?.unionWithKeys?.length) {
				let text = `${this.initialPrimaryData[header.key] ?? ""} `;
				header.unionWithKeys.forEach(unionKey => {
					text = text.concat(
						this.initialPrimaryData[unionKey.key]
							? `${this.initialPrimaryData[unionKey.key]}${unionKey.separator}`
							: "",
					);
				});
				return `${header.name}: ${text.length > 1 ? text : "Sem informação"}`;
			} else {
				return `${header.name}: ${
					header.onFormat?.(this.initialPrimaryData[header.key]) ||
					this.initialPrimaryData[header.key] ||
					"Sem informação"
				}`;
			}
		});

		navigator.clipboard.writeText(formattedTexts.filter(text => text).join("\n"));
		showSuccessAlert("", "Dados principais copiados");
	}

	closeModal() {
		this.modalIsOpen = false;
	}

	changeSearchModalStatus() {
		this.isSearchModalOpen = this.isSearchModalOpen === true ? false : true;
	}
}
