



































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CustomButton from "@/layouts/components/Button.vue";
import Select from "@/layouts/components/Select.vue";
import Input from "@/layouts/components/Input.vue";
import MessageTemplateAppModel from "@/api/message_template_app.model";

@Component({
	components: {
		"custom-button": CustomButton,
		Select,
		Input,
	},
})
export default class ChooseStandardMessage extends Vue {
	@Prop() id: string;
	@Prop({ default: "" }) modalStyle!: string;
	@Prop({ default: "" }) title!: string;
	@Prop({ default: "" }) titlesStyle!: string;
	@Prop({ default: "" }) subtitle!: string;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "" }) iconSize!: string;
	@Prop({ default: "mb-2" }) backButtonStyle!: string;
	@Prop({ default: "Voltar" }) backButtonText!: string;
	@Prop() corporateClientId!: string;
	@Prop({ default: false }) isOpen!: boolean;
	@Prop({ default: false }) withBackButton!: boolean;
	@Prop({ default: null }) onBackButton!: Function;
	@Prop({ default: () => [] }) buttons!: any[];
	@Prop({ default: () => null }) onModalClosed!: () => void;

	isModalOpen = this.isOpen;
	messageTemplateAppModel = MessageTemplateAppModel;

	isMessageTemplateLoading: boolean = false;
	messageTemplatesOptions: any[] = [];
	messageTemplateIdFilter: string = "";
	currentMessageTemplate: any = {};
	parsedMessageTemplate: string = "";
	matchedValues: string[] = [];
	storedValues: { referenceKey?: string; value?: string }[] = [];
	buttonDisabled = true;
	buttonDisabledValidation: boolean[] = [];

	messageOptions: { value: string; name: string }[] = [];

	data() {
		return { isModalOpen: this.isOpen };
	}

	@Watch("messageTemplateIdFilter")
	changeButtonStatus() {
		this.buttonDisabled = this.messageTemplateIdFilter ? false : true;
	}

	closeModal() {
		this.isModalOpen = false;
		if (this.onModalClosed && typeof this.onModalClosed === "function") {
			this.onModalClosed();
		}
	}
	@Watch("isOpen")
	async isOpenChanged() {
		this.isModalOpen = this.isOpen;
		if (this.isModalOpen) {
			await this.loadAllMessageTemplates();
		}
	}

	async changeTemplate() {
		if (this.messageTemplateIdFilter) {
			this.currentMessageTemplate = await this.loadMessageTemplates(this.messageTemplateIdFilter);
		}
	}

	async loadMessageTemplates(id: string) {
		return this.messageTemplateAppModel.getOne(id);
	}

	async loadAllMessageTemplates() {
		const messageTemplates = await this.messageTemplateAppModel.getMany({
			corporateClientIds: [this.corporateClientId],
		});

		this.messageOptions = messageTemplates.map((messageTemplate: any) => {
			return { name: messageTemplate.name, value: messageTemplate.id };
		});
	}

	chooseStandardMessage() {
		const standardMessage = {
			id: this.messageTemplateIdFilter,
			text: this.currentMessageTemplate.text,
			name: this.currentMessageTemplate.name,
		};
		this.$emit("chooseStandardMessage", standardMessage);
	}
}
