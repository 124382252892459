import { CrudModel } from "./_crud";
import SolutionAppModel from "./solution_app.model";
import QualificationModel from "./qualification_app.model";

export class SolutionQualificationModel extends CrudModel {
	constructor() {
		super(
			"app/solutionQualifications",
			[],
			[
				{ fieldKey: "solution", model: SolutionAppModel },
				{ fieldKey: "qualification", model: QualificationModel },
			],
		);
	}
}

export default new SolutionQualificationModel();
