















































import { Component, Prop, Vue } from "vue-property-decorator";
import { getFieldContent } from "@/helpers/utils";
import InitialsBadge from "./InitialsBadge.vue";
import GuideBalloon from "./GuideBalloon.vue";
import { Client } from "@/entities/client";
import { ChatMember } from "@/interfaces/chat";

export type tagVariant = "primary" | "secondary";

@Component({
	components: {
		"initials-badge": InitialsBadge,
		GuideBalloon,
	},
})
export default class CustomClientCard extends Vue {
	@Prop({ default: () => [] }) cardData!: ChatMember;
	@Prop({ default: "" }) customClass!: string;
	@Prop({ default: (event: Event) => null }) onClick!: (
		event: Event,
		buttonAction?: string,
		eventKind?: string,
	) => void;

	primaryStyle = "text-journey-black font-bold";
	secondaryStyle = "text-journey-gray font-bold text-sm";
	currentClient: Partial<Client> = {};
	getFieldContent = getFieldContent;
	cardSubData = "";

	baseCss =
		"flex flex-row pl-2.5 pr-2 py-2.5 shadow-md bg-journey-white justify-start gap-x-4 items-center rounded-r-md border-l-4 border-journey-emerald-green";

	handleClick(event: Event, eventKind: "favoriteClient" | "removeRelatedClient") {
		this.$emit(eventKind, this.cardData);
	}

	mounted() {
		this.currentClient = this.cardData.client!;
		this.formatContent();
	}

	formatContent() {
		const properties: { key: keyof Client; name: string }[] = [
			{ key: "phoneNumber", name: "Telefone" },
			{ key: "secondPhoneNumber", name: "Telefone alternativo" },
			{ key: "contactPhoneNumber", name: "Contato" },
		];

		properties.forEach((property, index) => {
			if (this.currentClient && this.currentClient[property.key] && (index === 0 || !this.cardSubData.length)) {
				this.cardSubData = this.cardSubData.concat(`${property.name}: ${this.currentClient[property.key]}`);
			} else if (this.currentClient && this.currentClient[property.key]) {
				this.cardSubData = this.cardSubData.concat(`\n${property.name}: ${this.currentClient[property.key]}`);
			}
		});

		return this.cardSubData;
	}
}
