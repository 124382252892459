var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-grow-1 items-start cursor-pointer border-l-4 border-journey-emerald-green"},[_c('div',{staticClass:"flex flex-col",on:{"click":_vm.handleClick}},[_c('div',{staticClass:"flex flex-grow-1 justify-between"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"pr-0.5",class:_vm.primaryStyle},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.mainChannelMemberFullName))])]),_c('div',{staticClass:"flex items-center"},[(_vm.shouldShowPinChannel)?_c('GuideBalloon',{style:(_vm.pushPinColor),attrs:{"icon":'push-pin',"iconStyle":'h-4 font-bold text-journey-green',"isStaticBalloon":true},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick($event, 'pinChannel')}}}):_vm._e(),_c('GuideBalloon',{class:'pl-0.5 text-journey-emerald-green',style:(_vm.buildingGreen),attrs:{"icon":'building',"iconStyle":'h-4 font-bold',"isStaticBalloon":true,"text":_vm.cardData.corporateClient.name}}),(_vm.isLoyalUser)?_c('GuideBalloon',{class:'pl-0.5 text-journey-emerald-green',attrs:{"featherIcon":{
							icon: 'BookmarkIcon',
							size: '16',
						},"iconStyle":'h-5 font-bold',"isStaticBalloon":true,"text":'Cliente fidelizado'}}):_vm._e(),_c('p',{staticClass:"mb-0 pl-0.5"},[_vm._v(_vm._s(_vm.cardData.phoneNumber))]),(_vm.clientMembers.length)?_c('GuideBalloon',{class:'px-0.5 text-journey-emerald-green',attrs:{"text":_vm.clientMembers
								.map(function (member) { return member.client
										? ("" + (member.client.name) + (member.client.lastName ? ' ' + member.client.lastName : ''))
										: ''; }
								)
								.join(' | '),"featherIcon":{
							icon: 'PocketIcon',
							size: '16',
						},"iconStyle":'h-5 font-bold',"isStaticBalloon":true}}):_vm._e(),(
							!_vm.lastChatMessage.user &&
							(([_vm.ChatMessageStatus.SENT, _vm.ChatMessageStatus.DELIVERED].includes(_vm.lastChatMessage.status) &&
								[_vm.ChatMessageKind.DEFAULT, _vm.ChatMessageKind.RECEPTIVE].includes(_vm.lastChatMessage.kind)) ||
								([_vm.ChatMessageKind.BOT, _vm.ChatMessageKind.RECEPTIVE].includes(_vm.lastChatMessage.kind) &&
									_vm.lastChatMessage.status === _vm.ChatMessageStatus.FAILED &&
									_vm.lastChatMessage.isRead === false))
						)?_c('GuideBalloon',{class:'px-0.5 red-alert',attrs:{"text":'Nova mensagem',"featherIcon":{
							icon: 'BellIcon',
							size: '16',
						},"isStaticBalloon":true,"iconStyle":'h-5 font-bold'}}):_vm._e()],1)]),_c('div',{staticClass:"flex items-center",class:_vm.textJourney},[_vm._v(" "+_vm._s(_vm.lastChatMessage.createdDate ? _vm.formatISODate(_vm.lastChatMessage.createdDate) : "")+" ")])]),_c('p',{staticClass:"flex items-center mb-0 text-base",class:_vm.secondaryStyle},[(_vm.lastChatMessage.mediaFiles && _vm.lastChatMessage.mediaFiles.length)?_c('GuideBalloon',{class:'pr-0.5',attrs:{"featherIcon":{
					icon: 'ImageIcon',
					size: '14',
				},"isStaticBalloon":true,"iconStyle":'h-5 font-bold'}}):_vm._e(),_vm._v(" "+_vm._s(_vm.lastChatMessage.text ? _vm.lastChatMessage.text.length > 32 ? ((_vm.lastChatMessage.text.substring(0, 32)) + "...") : _vm.lastChatMessage.text : "")+" ")],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }