



























import { Component, Vue, Prop } from "vue-property-decorator";
import BaseModal from "@/layouts/components/BaseModal.vue";
import Button from "@/layouts/components/Button.vue";
import { showSuccessAlert } from "@/helpers";

@Component({
	components: {
		BaseModal,
		Button,
	},
})
export default class SolutionConversationContextModal extends Vue {
	@Prop({ default: false }) isOpen: boolean;
	@Prop({ default: false }) isLoading: boolean;
	@Prop({ default: () => null }) onModalClosed!: () => void;

	localText = "";

	emitTextAndCloseModal() {
		this.$emit("set-conversation-context", this.localText);
		showSuccessAlert("Contexto da conversa salvo com sucesso.");
		this.onModalClosed();
	}
}
