























import { Component, Prop, Vue } from "vue-property-decorator";
import { tertiaryBoldBody } from "@/helpers/styleClassHelpers";

@Component
export default class DropdownSection extends Vue {
	@Prop({ default: false }) initialIsOpen: boolean;
	@Prop({ default: "Section" }) name: string;
	@Prop({ default: "gap-x-2" }) leftHeaderClass: string;
	@Prop({ default: "gap-x-5" }) rightHeaderClass: string;

	isOpen: boolean = false;
	tertiaryBoldBody = tertiaryBoldBody;

	created() {
		this.isOpen = this.initialIsOpen;
	}
}
