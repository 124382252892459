import { CrudModel } from "./_crud";
import { crudGet, crudRead } from "./_request";

export class MessageTemplateAppModel extends CrudModel {
	constructor() {
		super("app/messageTemplate", [], []);
	}

	async list(params?: any) {
		return crudGet(this.endpoint, "", params);
	}

	async getOne(id: string, queryParameters: any = {}) {
		return crudRead(this.endpoint, id, queryParameters);
	}

	async getMany(params?: any) {
		return crudGet(this.endpoint, "standard", params);
	}
}

export default new MessageTemplateAppModel();
