import NeedAppModel from "./need_app.model";
import SolutionAppModel from "./solution_app.model";
import { CrudModel } from "./_crud";

export class SolutionNeedAppModel extends CrudModel {
	constructor() {
		super(
			"app/solutionNeeds",
			[],
			[
				{ fieldKey: "solution", model: SolutionAppModel },
				{ fieldKey: "need", model: NeedAppModel },
			],
		);
	}
}

export default new SolutionNeedAppModel();
