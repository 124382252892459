





































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import BaseModal from "@/layouts/components/BaseModal.vue";
import Button from "@/layouts/components/Button.vue";
import UserCard from "@/layouts/components/UserCard.vue";
import GuideBalloon from "@/layouts/components/GuideBalloon.vue";
import Checkbox from "@/layouts/components/Checkbox.vue";
import SolutionCard from "@/layouts/components/SolutionCard.vue";
import Calendar from "@/layouts/components/Calendar.vue";
import Select from "@/layouts/components/Select.vue";
import Input from "@/layouts/components/Input.vue";
import EntityForm from "@/views/entity/EntityForm.vue";
import { IUser, translateGender } from "@/interfaces/user";
import { ISolution } from "@/interfaces/solution";
import { IAppointment } from "@/interfaces/appointment";
import { crudCreate, crudGet, crudPatch } from "@/api/_request";
import { showErrorAlert, showSuccessAlert } from "@/helpers";
import { readableDay, findNextWorkDayAfterDays, toIsoDate, formatDateToISO } from "@/helpers/calendar";
import { DeepPartial } from "@/entities/base_entity";
import { ICategory } from "@/interfaces/solution_category";

import CorporateClientAppModel from "../../api/corporate_client_app.model";
import SolutionAppModel from "../../api/solution_app.model";
import { INeed } from "@/interfaces/need";
import CorporateClientAndPlans from "./CorporateClientAndPlans.vue";
import { isIsoDate } from "@/helpers/utils";

const nextWorkDay = findNextWorkDayAfterDays(toIsoDate(new Date()), 7);

@Component({
	components: {
		BaseModal,
		Calendar,
		Button,
		UserCard,
		SolutionCard,
		GuideBalloon,
		Checkbox,
		Select,
		Input,
		EntityForm,
	},
})
export default class AppointmentsConfirmationModal extends Vue {
	@Prop({ default: false }) isOpen: boolean;
	@Prop({ default: () => null }) onModalClosed!: (shouldClearTheSearch?: boolean) => void;
	@Prop({ default: () => null }) onReloadParent!: Function;
	@Prop({ default: () => [] }) qualifications!: any[];
	@Prop({ default: () => [] }) needs!: INeed[];
	@Prop({}) user!: IUser;
	@Prop({}) header!: any;
	@Prop({}) solutions!: ISolution[];
	@Prop({ default: false }) isCreationType!: boolean;

	hasReturnDate = true;
	selectedReturnDate = nextWorkDay;
	isCalendarOpen = false;

	solution: Partial<ISolution> = {};
	isLoading = false;

	solutionAppModel = SolutionAppModel;
	fieldsByActiveInput: any = {};

	buttonDisabled = false;

	withPostalCode = true;
	shouldDisableButton = false;

	defaultFormColumns: any[] = [
		{
			key: "category",
			required: true,
			keyEntity: "category.id",
		},
		{ key: "name", required: true },
	];
	cleanFormKeys = {
		deleteKeys: ["corporateClient", "corporateClient.id", "id", "corporateAndPlans"],
		holdKeys: ["category.id"],
	};

	formColumns: any[] = [];

	categories: ICategory[] = [];
	solutionInsurerPlansField: any;
	solutionNeedsField: any;
	solutionQualificationsField: any;
	solutionCorporateClientField: any;

	@Watch("isOpen")
	handleModalType() {
		this.shouldDisableButton = false;
		if (this.isOpen && !this.isCreationType) {
			this.buttonDisabled = false;
		}
	}

	get todayIsoDate() {
		return toIsoDate(new Date());
	}

	get relatedNeedName() {
		return this.solutions?.[0]?.relatedNeed?.name ?? "Nenhuma necessidade identificada.";
	}

	async created() {
		this.categories = await crudGet("app", "category");
		await this.loadCorporateClients();
		await this.loadPlans();
		await this.loadNeedsAndQualifications();

		this.formColumns = this.defaultFormColumns;
		this.fieldsByActiveInput = {
			isHealthInputActive: [
				this.corporateAndPlansField,
				this.solutionCorporateClientField,
				this.solutionInsurerPlansField,
			],
			isAddressInputActive: [
				{ key: "isFavorite", inputKind: "switchButtons" },
				{
					key: "address.postCode",
					autoComplete: true,
					autoCompleteKeys: ["address.street", "address.neighborhood", "address.city", "address.state"],
				},
				{ key: "address.street" },
				{ key: "address.number" },
				{ key: "address.complement" },
				{ key: "address.neighborhood" },
				{ key: "address.city" },
				{ key: "address.state" },
			],
			isAvailabilityInputActive: [{ key: "availabilities" }],
			isContactInputActive: [{ key: "phoneNumber" }],
			isDescriptionInputActive: [{ key: "description" }],
			isCnpjInputActive: [{ key: "cnpj" }],
			isFileUploadInputActive: [{ key: "attachmentUrl" }],
			isGenderInputActive: [{ key: "gender", onTransform: translateGender }],
			isNeedsInputActive: [this.solutionNeedsField],
			isQualificationsInputActive: [this.solutionQualificationsField],
			isSpecialtiesKindInputActive: [{ key: "specialties", kind: "multiselect" }],
			isSubcategoryInputActive: [{ key: "subcategory" }],
		};
	}

	//TODO: create mixin of solutions and sync logic
	async loadCorporateClients() {
		const { data: corporateClients } = await CorporateClientAppModel.search({ page: 1, limit: 0 });

		this.solutionCorporateClientField = {
			key: "corporateClient.id",
			kind: "readOnly",
			name: "Cliente corporativo",
			getInformationOtherKey: true,
			otherKey: "solutionInsurerPlans",
			relationKeyId: "insurerPlan.corporateClient.id",
			relationKeyName: "insurerPlan.corporateClient.name",
			onTransform: this.formatReadOnlyCorporateClient,
		};
	}

	formatReadOnlyCorporateClient(corporateClients: any[]) {
		if (!corporateClients) {
			return "";
		}
		const formatCorporateClients = corporateClients?.reduce(
			(stringCorporateClients, currentCorporateClient, indexCorporateClient) => {
				return `${stringCorporateClients} ${currentCorporateClient.name}${
					indexCorporateClient !== corporateClients.length - 1 && corporateClients.length > 1 ? "," : ""
				}`;
			},
			"",
		);

		return formatCorporateClients;
	}

	async loadPlans(corporateClients?: { id: string; name: string }[]) {
		this.solutionInsurerPlansField = {
			key: "solutionInsurerPlans",
			name: "Planos de saúde",
			relationKey: "insurerPlan",
			required: false,
			formatEntity: true,
			modifyValue: true,
			onTransform: this.formatReadOnlyInsurerPlan,
			kind: "readOnly",
		};
	}

	formatReadOnlyInsurerPlan(plans: any[]) {
		if (!plans) {
			return;
		}
		return plans?.reduce((formattedPlansString, currentPlan, index) => {
			return `${formattedPlansString} ${currentPlan.insurerPlan?.name ?? currentPlan["insurerPlan.name"]}${
				index !== plans.length - 1 && plans.length > 1 ? "," : ""
			}`;
		}, "");
	}

	corporateAndPlansField = {
		key: "corporateAndPlans",
		name: "Clientes corporativos e planos",
		required: false,
		relationKey: "solutionInsurerPlans",
		kind: "table",
		customFormat: true,
		customFunctionFormat: this.createCorporateAndPlans,
		component: CorporateClientAndPlans,
		columns: [
			{
				key: "corporateClient",
				name: "Cliente corporativo",
				kind: "text",
				hasSelect: false,
				onTransform: (corporateClient: any) => corporateClient.name,
			},
			{
				key: "plans",
				name: "Planos",
				kind: "text",
				hasSelect: false,
				onTransform: (plans: any[]) => {
					return plans.reduce(
						(finalPlans: string, currentPlan: { insurerPlan: { id: string; name: string } }, indexPlan: number) => {
							finalPlans = `${finalPlans} ${currentPlan.insurerPlan?.name}${
								indexPlan !== plans.length - 1 && plans.length > 1 ? "," : ""
							}`;
							return finalPlans;
						},
						"",
					);
				},
			},
		],
	};

	createCorporateAndPlans(entity: any) {
		Vue.set(entity, "corporateAndPlans", []);
		entity.corporateAndPlans = [];
		entity.solutionInsurerPlans?.forEach((plan: any) => {
			if (
				!entity.corporateAndPlans.filter(
					(item: any) =>
						item.corporateClient?.id ===
						(plan["insurerPlan.corporateClient.id"] || plan.insurerPlan?.corporateClient?.id),
				).length
			) {
				entity.corporateAndPlans.push({
					corporateClient: {
						id: plan["insurerPlan.corporateClient.id"] || plan.insurerPlan?.corporateClient?.id,
						name: plan["insurerPlan.corporateClient.name"] || plan.insurerPlan?.corporateClient?.name,
					},
					plans: [
						{
							insurerPlan: {
								id: plan["insurerPlan.id"] || plan.insurerPlan?.id,
								name: plan["insurerPlan.name"] || plan.insurerPlan?.name,
								description: plan["insurerPlan.description"] || plan.insurerPlan?.description,
								corporateClient: {
									id: plan["insurerPlan.corporateClient.id"] || plan.insurerPlan?.corporateClient?.id,
									name: plan["insurerPlan.corporateClient.name"] || plan.insurerPlan?.corporateClient?.name,
								},
							},
						},
					],
				});
			} else {
				entity.corporateAndPlans
					.find(
						(item: any) =>
							item.corporateClient?.id === plan["insurerPlan.corporateClient.id"] ||
							plan.insurerPlan?.corporateClient?.id,
					)
					.plans.push({
						insurerPlan: {
							id: plan["insurerPlan.id"] || plan.insurerPlan?.id,
							name: plan["insurerPlan.name"] || plan.insurerPlan?.name,
							description: plan["insurerPlan.description"] || plan.insurerPlan?.description,
							corporateClient: {
								id: plan["insurerPlan.corporateClient.id"] || plan.insurerPlan?.corporateClient?.id,
								name: plan["insurerPlan.corporateClient.name"] || plan.insurerPlan?.corporateClient?.name,
							},
						},
					});
			}
		});
	}

	async loadNeedsAndQualifications() {
		this.solutionNeedsField = {
			key: "solutionNeeds",
			name: "Necessidades",
			kind: "multiselect",
			relationKey: "need",
			required: true,
			formatEntity: true,
			options: this.needs.map((need: any) => {
				return { name: need.name, value: { need: { id: need.id, name: need.name } } };
			}),
		};

		this.solutionQualificationsField = {
			key: "solutionQualifications",
			name: "Qualificações",
			kind: "multiselect",
			relationKey: "qualification",
			formatEntity: true,

			options: this.qualifications.map((qualification: any) => {
				return {
					name: qualification.name,
					value: { qualification: { id: qualification.id, name: qualification.name } },
				};
			}),
		};
	}

	async handleInputsChange(value: any, fieldKey: string) {
		//CHECK: Verificar passagem de {value: "category", fieldKey: undefined}, causando erro, tratado pela validação if(... && value)
		if (fieldKey === "category" && value) {
			//update fields visibility
			const category = this.categories.find(({ id }) => id === value)!;
			const currentFormColumns: any[] = [];
			Object.keys(category).forEach(key => {
				const shouldAddField = !["id", "name"].includes(key) && category[<keyof ICategory>key];
				if (shouldAddField) {
					const fieldsArray = this.fieldsByActiveInput[<keyof ICategory>key]!;
					if (fieldsArray && fieldsArray.length) {
						currentFormColumns.push(...fieldsArray);
					}
				}
			});
			this.formColumns = [...this.defaultFormColumns, ...currentFormColumns];
		}

		if (fieldKey === "corporateClient.id") {
			await this.loadPlans(value);
		}
	}

	get readableReturnDate() {
		return readableDay(this.selectedReturnDate);
	}

	async confirmAppointmentsOrCreateSolution() {
		this.shouldDisableButton = true;
		setTimeout(() => {
			this.shouldDisableButton = false;
		}, 20000);
		if (this.isCreationType) {
			const form: any = this.$refs.solutionForm;
			try {
				this.isLoading = true;
				form.cleanForm();
				if (this.user) {
					Object.assign(form.entity, { isIndividualForClient: { id: this.user.id } });
				}
				await crudCreate("app/solution", form.entity);
				showSuccessAlert("Solicitação de solução confirmada.");
				form.entity = {};
				this.onModalClosed();
			} catch (error) {
				showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
			} finally {
				this.isLoading = false;
			}
			return;
		}

		try {
			this.isLoading = true;

			const appointmentsToBeCreated: DeepPartial<IAppointment>[] = [];
			const appointmentsIdsToBeConfirmed: string[] = [];

			this.solutions.forEach(solution => {
				if (solution.appointment) {
					appointmentsIdsToBeConfirmed.push(solution.appointment.id!);
				} else {
					const need = solution.relatedNeed;
					const conversationContext = solution.conversationContext;
					if (this.hasReturnDate && !isIsoDate(this.selectedReturnDate)) {
						this.selectedReturnDate = formatDateToISO(this.selectedReturnDate);
					}
					const appointment: DeepPartial<IAppointment> = {
						client: { id: this.user.id },
						solution: { id: solution.id },
						...(need?.id && { need, reportedNeedName: need.name }),
						...(this.hasReturnDate ? { returnDate: this.selectedReturnDate } : {}),
						...(conversationContext ? { conversationContext } : {}),
					};
					appointmentsToBeCreated.push(appointment);
				}
			});

			if (appointmentsIdsToBeConfirmed.length) {
				await crudPatch("app/solutionAppointment", "bulk/confirm", {
					appointmentIds: appointmentsIdsToBeConfirmed,
					appointmentsData: {
						...(this.hasReturnDate ? { returnDate: this.selectedReturnDate } : {}),
					},
				});
			}

			if (appointmentsToBeCreated.length) {
				await crudCreate("app/solutionAppointment/bulk", { appointments: appointmentsToBeCreated });
			}

			this.$emit("clear-conversation-context");

			//true param on "onModalClosed" means to call "resetAll" at CustomerProfile.vue
			this.onModalClosed?.(true);
			this.selectedReturnDate = nextWorkDay;
			this.onReloadParent();
			showSuccessAlert("Solução para cliente criada com sucesso!");
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isLoading = false;
		}
	}
}
