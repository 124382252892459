








































































































import { ChatMember, ChatMessage, ChatMessageKind, ChatMessageStatus } from "@/interfaces/chat";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { formatDate } from "../../helpers/utils";
import GuideBalloon from "./GuideBalloon.vue";

@Component({
	components: {
		GuideBalloon,
	},
})
export default class ChatMemberCard extends Vue {
	@Prop({ default: true }) shouldShowPinChannel: boolean;
	@Prop({ default: () => [] }) cardData!: any;
	@Prop({ default: () => [] }) lastChatMessage!: ChatMessage;
	@Prop({ default: () => [] }) clientMembers!: ChatMember[];
	@Prop({ default: () => [] }) cardHeaders!: any;
	@Prop() currentTime!: any;
	@Prop({ default: (event: Event) => null }) onClick!: (event: Event) => void;

	primaryStyle = "text-journey-black font-bold";
	secondaryStyle = "text-journey-gray font-bold";
	dangerStyle = "text-journey-orange font-bold";
	isLoyalUser: boolean = false;
	textJourney = this.primaryStyle;
	mainChannelMemberFullName: string = "";
	ChatMessageKind = ChatMessageKind;
	ChatMessageStatus = ChatMessageStatus;

	isPinnedChannel = false;

	buildingGreen = "filter: invert(44%) sepia(8%) saturate(2840%) hue-rotate(134deg) brightness(93%) contrast(95%);";

	pushPinGreen = "filter: invert(44%) sepia(8%) saturate(2840%) hue-rotate(134deg) brightness(93%) contrast(95%);";
	pushPinGray = "filter: invert(94%) sepia(11%) saturate(119%) hue-rotate(177deg) brightness(90%) contrast(91%);";

	pushPinColor = this.isPinnedChannel ? this.pushPinGreen : this.pushPinGray;

	mounted() {
		this.isLoyalUser = !!this.clientMembers.find(member => {
			if (
				member.client &&
				member.client.loyalUser /*&&
							member.client.insurerPlanRelationKind === InsurerPlanRelationKind.HOLDER*/
			) {
				return true;
			}

			return false;
		});

		const mainChannelMember = this.clientMembers.find(member => member.isMainMember);
		this.mainChannelMemberFullName = mainChannelMember
			? `${mainChannelMember.client?.name}${
					mainChannelMember.client?.lastName ? " " + mainChannelMember.client?.lastName : ""
			  }`
			: "";

		this.isPinnedChannel = !!this.cardData.channelFixationDate;
		this.pushPinColor = this.isPinnedChannel ? this.pushPinGreen : this.pushPinGray;
	}

	@Watch("currentTime")
	updateLastChatMessageTime() {
		if (this.lastChatMessage) {
			this.changeHourColor();
		}
	}

	formatISODate(date: Date) {
		this.changeHourColor();

		return formatDate(date, "short", "short").split(" ")[1];
	}

	get propCurrentTime() {
		return this.currentTime;
	}

	changeHourColor() {
		const isClientLastMessage = this.lastChatMessage.phoneNumber ? true : false;
		const now = new Date();
		const wasLastMessageFifteenMinutesAgo =
			Math.abs(
				new Date(this.lastChatMessage.createdDate).getTime() - this.currentTime
					? this.currentTime.getTime()
					: now.getTime(),
			) /
				1000 /
				60 >
			5;

		if (
			(isClientLastMessage || this.lastChatMessage.kind === ChatMessageKind.RECEPTIVE) &&
			wasLastMessageFifteenMinutesAgo
		) {
			this.textJourney = this.dangerStyle;
		} else {
			this.textJourney = this.primaryStyle;
		}
	}

	handleClick(event: Event, kind: "openChannel" | "pinChannel" = "openChannel") {
		event.preventDefault();
		if (kind === "pinChannel") {
			this.$emit("pinChannel", this.cardData);
		} else if (this.onClick && typeof this.onClick === "function") {
			this.onClick(event);
		}
	}
}
