







































































import { Component, Vue, Prop } from "vue-property-decorator";
import BaseModal from "@/layouts/components/BaseModal.vue";
import CustomSearch from "@/layouts/components/CustomSearch.vue";
import Button from "@/layouts/components/Button.vue";
import vSelect from "vue-select";
import { crudGet } from "@/api/_request";
import { ISolution } from "@/interfaces/solution";
import { showErrorAlert } from "@/helpers";
import { INeed } from "@/interfaces/need";
import { IQualification } from "@/interfaces/qualification";
import { ICategory } from "@/interfaces/solution_category";

@Component({
	components: {
		VueSelect: vSelect,
		BaseModal,
		Button,
		CustomSearch,
	},
})
export default class SolutionFilterModal extends Vue {
	@Prop({ default: false }) isOpen: boolean;
	@Prop({ default: () => null }) onModalClosed!: () => void;
	@Prop({ required: true }) category!: Pick<ICategory, "id" | "name">;
	@Prop({ required: true }) data!: { clientId: string; relatedNeed: INeed; conversationContext: string };
	@Prop({ default: () => [] }) qualifications: IQualification[];

	isLoading: boolean = false;
	maxKmDistance: number | null = 1000;
	selectedQualificationsIds: string[] = [];

	isFavoriteOptions = [
		{ name: "Sim", value: true },
		{ name: "Não", value: false },
		{ name: "Indiferente", value: null },
	];
	isFavorite: boolean | null = null;

	getAppliedFiltersVisualData() {
		const isFavorite = `Rede preferencial: ${
			this.isFavoriteOptions.find(({ value }) => value === this.isFavorite)?.name
		}`;
		const distance = this.maxKmDistance ? `${this.maxKmDistance} km` : "";
		const qualificationNames = this.qualifications
			.filter(({ id }) => this.selectedQualificationsIds.includes(id!))
			.map(({ name }: { name: string }) => name);
		const data = [distance, isFavorite, ...qualificationNames].filter(item => item);

		return data.length ? `Filtros: ${data.join(", ")}` : "";
	}

	async filterSolutionsSection() {
		this.maxKmDistance = this.maxKmDistance || null;

		if (this.selectedQualificationsIds.includes("all")) {
			this.selectedQualificationsIds = [];
		}

		try {
			this.isLoading = true;
			const {
				solutionsSectionedByCategories: sections = {},
			}: {
				solutionsSectionedByCategories: Record<string, ISolution[]>;
				preSelectedSolutions: ISolution[];
			} = await crudGet("app", "solution", {
				qualificationsIds: JSON.stringify(this.selectedQualificationsIds),
				maxKmDistance: this.maxKmDistance,
				isFavorite: this.isFavorite,
				category: { name: this.category.name },
				needId: this.data.relatedNeed.id,
				clientId: this.data.clientId,
				page: 1,
				limit: 0,
			});

			const section = Object.values(sections)?.[0] ?? [];
			if (section.length) {
				section[0].filterVisualData = this.getAppliedFiltersVisualData();
				section.forEach(solution => {
					solution.relatedNeed = this.data.relatedNeed;
					solution.conversationContext = this.data.conversationContext;
				});
			}

			this.$emit("set-filtered-section", this.category.id, section);
		} catch (e) {
			showErrorAlert("Ocorreu um erro. Tente novamente.");
		}

		this.isLoading = false;
	}

	removeFilters() {
		this.selectedQualificationsIds = [];
		this.maxKmDistance = null;
		this.isFavorite = null;
	}
}
