import { ChatChannelStatus } from "@/interfaces/chat";
import { CrudModel } from "./_crud";
import { crudGet, crudPost } from "./_request";

export class GetDirectChannelDto {
	phoneNumber?: string;
	client?: string;
	shouldSendReceptiveMessage?: boolean;
	corporateClientId?: string;
	chatChannelId?: string;
}

export class GetMessagesDto {
	chatChannel: string;
	lastChatMessageDate?: Date;
}

export class ListChannelsDto {
	limit!: number;
	page!: number;
	searchParam?: string;
}

export class GetChannelsDto {
	status?: ChatChannelStatus;
	shouldGetQueueChannels?: boolean;
	shouldIgnoreChannelStatus?: boolean;
}

export class ChatModel extends CrudModel {
	constructor() {
		super("/chat");
	}

	async getChannelInfo(chatChannelId: string) {
		return crudPost(this.endpoint, "getChannelInfo", { chatChannelId });
	}

	async getDirectChannel(getDirectChannelDto: GetDirectChannelDto) {
		return crudPost(this.endpoint, "getDirectChannel", getDirectChannelDto);
	}

	async listChannels(listChannelsDto: ListChannelsDto) {
		return crudPost(this.endpoint, "listChannels", listChannelsDto);
	}

	async getChannels(getChannelsDto?: GetChannelsDto) {
		return crudPost(this.endpoint, "getChannels", getChannelsDto ?? {});
	}

	async getMessages(getMessagesDto: GetMessagesDto) {
		return crudPost(this.endpoint, "getMessages", getMessagesDto);
	}

	async updateChannelMessagesStatus(getMessagesDto: GetMessagesDto) {
		return crudPost(this.endpoint, "updateChannelMessageStatus", getMessagesDto);
	}

	async bindClientsToChannel(clients: string[], chatChannel: string) {
		return crudPost(this.endpoint, "bindClientsToChannel", { clients, chatChannel });
	}

	async setChatChannelMainClientMember(chatMember: string, chatChannel: string) {
		return crudPost(this.endpoint, "setChatChannelMainClientMember", { chatMember, chatChannel });
	}

	async deleteChatChannelMainClientMember(chatMember: string, chatChannel: string) {
		return crudPost(this.endpoint, "deleteChatChannelMainClientMember", { chatMember, chatChannel });
	}

	async pinChatChannelOnQueue(chatChannel: string) {
		return crudPost(this.endpoint, "pinChatChannelOnQueue", { chatChannel });
	}

	async closeChannel(chatChannel: string, shouldSendFeedbackPoll?: boolean) {
		return crudPost(this.endpoint, "closeChannel", { chatChannel, shouldSendFeedbackPoll });
	}

	async transferChannel(chatChannel: string, user: string) {
		return crudPost(this.endpoint, "transferChannel", { chatChannel, user });
	}

	async report(corporateClientId?: string) {
		return crudPost(this.endpoint, "report", { corporateClientId });
	}
}

export default new ChatModel();
