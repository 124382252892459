






































































import { ChatMessage } from "@/interfaces/chat";
import { Component, Prop, Vue } from "vue-property-decorator";
import GuideBalloon from "./GuideBalloon.vue";

@Component({
	components: { GuideBalloon },
})
export default class MediaViewer extends Vue {
	@Prop({ default: false }) disabled!: boolean;
	@Prop({ default: (event: Event) => null }) onClick!: (event: Event, buttonAction?: string) => void;
	@Prop({ default: "" }) buttonAction!: string;
	@Prop() mediaExtension!: "jpeg" | "jpg" | "png" | "mp4" | "ogg" | "pdf" | "webp" | "vcard";
	@Prop() chatMessage!: ChatMessage;

	handleClick(event: Event) {
		if (this.disabled) {
			return;
		}
		event.preventDefault();
		if (this.onClick && typeof this.onClick === "function") {
			this.onClick(event, this.buttonAction);
		}
	}

	openFile(fileUrl: string) {
		window.open(fileUrl, "_blank");
	}
}
