





































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CustomButton from "@/layouts/components/Button.vue";
import Select from "@/layouts/components/Select.vue";
import Input from "@/layouts/components/Input.vue";

@Component({
	components: {
		"custom-button": CustomButton,
		Select,
		"journey-input": Input,
	},
})
export default class ConfirmationModal extends Vue {
	@Prop() id: string;
	@Prop({ default: "" }) modalStyle!: string;
	@Prop() title: string;
	@Prop({ default: "" }) titlesStyle!: string;
	@Prop() subtitle: string;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "" }) iconSize!: string;
	@Prop({ default: "mb-2" }) backButtonStyle!: string;
	@Prop({ default: "Voltar" }) backButtonText!: string;
	@Prop({ default: false }) isOpen!: boolean;
	@Prop({ default: "chat:search" }) eventType!: string;
	@Prop({ default: false }) withBackButton!: boolean;
	@Prop({ default: null }) onBackButton!: Function;
	@Prop({ default: () => [] }) buttons!: any[];
	@Prop({ default: () => null }) onModalClosed!: () => void;
	@Prop({}) emitEventName: string;
	@Prop({ default: false }) shouldCallCancelEvent: boolean;

	isModalOpen = this.isOpen;

	data() {
		return { isModalOpen: this.isOpen };
	}

	closeModal() {
		this.isModalOpen = false;
		if (this.onModalClosed && typeof this.onModalClosed === "function") {
			this.onModalClosed();
		}
	}
	@Watch("isOpen")
	async isOpenChanged() {
		this.isModalOpen = this.isOpen;
	}

	emitEvent() {
		this.$emit(this.emitEventName);
		this.closeModal();
	}

	cancelEvent() {
		this.$emit("cancelMessage");
		this.closeModal();
	}
}
