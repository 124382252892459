import { ConnectionStatus } from "@/helpers/utils";
import { ChatMessageAction, ChatMessageMetadata } from "@/interfaces/chat";
import store from "@/store";
import { WS_URL } from "../../api.config";
import router from "@/router";

export const token = "";
export let socket: any = null;
export const channel: any = null;
export let socketStatus = ConnectionStatus.CLOSED;
export const socketEvent: any = {};
const CONNECTION_COUNT = 100;
let currentReconnectionCount = CONNECTION_COUNT;
const JOIN_CHAT_CHANNEL_DENIED_MESSAGE = "Não é possível acessar conversa em andamento";

export const getTimestamp = () => {
	return new Date().toISOString();
};

export const socketInit = async (currentUserToken: string) => {
	if (
		!currentUserToken ||
		!router.currentRoute.path.includes("/chat") ||
		socket?.readyState === WebSocket.OPEN ||
		socket?.readyState === WebSocket.CONNECTING
	) {
		return;
	}

	socket = new WebSocket(WS_URL);

	socket.onopen = (event: any) => {
		console.info(getTimestamp(), "[WS] Connection established");
		socketStatus = ConnectionStatus.ACTIVATED;
		socket.send(JSON.stringify({ event: "register", data: { token: currentUserToken } }));
		console.info(getTimestamp(), "[WS] Registered");
	};

	socket.onclose = (event: any) => {
		// if (event.wasClean) {
		// 	console.info(getTimestamp(), `[WS] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
		// 	socketStatus = ConnectionStatus.CLOSED;
		// } else {
		// 	console.error(getTimestamp(), "[WS] Connection died");
		// 	console.info("[WS] Reconnecting...");
		// 	if (currentReconnectionCount > 0) {
		// 		currentReconnectionCount--;
		// 		socketStatus = ConnectionStatus.CONNECTING;
		// 		setTimeout(() => {
		// 			socketInit(currentUserToken);
		// 		}, 500);
		// 	} else {
		// 		socketStatus = ConnectionStatus.FAILED;
		// 		console.info("[WS] Reconnection failed");
		// 		router.back();
		// 		currentReconnectionCount = CONNECTION_COUNT;
		// 	}
		// }
	};

	socket.onerror = (error: any) => {
		console.error(error);
	};
};

export const sendMessageSocket = (
	chatChannel: string,
	text?: string,
	contentVariables?: { referenceKey: string; value: string }[],
	mediaFiles?: string[],
	metadata?: ChatMessageMetadata,
	messageTemplate?: string,
	shouldUseAI = false,
) => {
	socket.send(
		JSON.stringify({
			event: "new_message",
			data: { chatChannel, text, contentVariables, mediaFiles, metadata, messageTemplate, shouldUseAI },
		}),
	);
};

export const transferChannel = (chatChannel: string, user: string) => {
	socket.send(JSON.stringify({ event: "transfer_channel", data: { chatChannel, user } }));
};

export const chatConnectionError = () => {
	console.error("Erro ao se conectar com chat, tente novamente");
};

export const initChat = async (currentUserToken: string) => {
	try {
		await socketInit(currentUserToken);

		return socket;
	} catch (error) {
		chatConnectionError();
	}
};

export const closeSocket = () => {
	currentReconnectionCount = CONNECTION_COUNT;
	if (socket) {
		socket.close();
	}
};
