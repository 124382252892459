





























import { Component, Prop, Vue } from "vue-property-decorator";
import GuideBalloon from "./GuideBalloon.vue";
import { ChatMessage, ChatMessageStatus } from "@/interfaces/chat";
import { BootstrapVue, IconsPlugin, BIcon } from "bootstrap-vue";
import { formatDate } from "../../helpers/utils";
import MediaViewer from "./MediaViewer.vue";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

@Component({
	components: {
		GuideBalloon,
		BIcon,
		MediaViewer,
	},
})
export default class ChatMessageCard extends Vue {
	@Prop({ default: () => [] }) chatMessage!: ChatMessage;
	@Prop({ default: "text-journey-black text-xs 2xl:text-sm font-bold mb-0" }) timeStyle!: string;
	@Prop({
		default:
			"text-journey-black font-bold max-w-2xl break-message-card-text text-10 2xl:text-base mb-0 whitespace-pre-wrap",
	})
	textStyle!: string;

	primaryStyle = "text-journey-black font-bold";
	secondaryStyle = "text-journey-gray font-bold text-sm";

	extensionFile: string = "";

	mounted() {
		const mediaFiles = this.chatMessage.mediaFiles ? this.chatMessage.mediaFiles : [];
		if (mediaFiles.length) {
			const mediaFilesUrlSplitted = mediaFiles[0].split(".");
			this.extensionFile = mediaFilesUrlSplitted[mediaFilesUrlSplitted.length - 1];
		}
	}

	formatISODate(date: Date) {
		return formatDate(date);
	}

	getBootstrapIcon(status: ChatMessageStatus): { icon: string; style: string; class: string } {
		const bootstrapIconDictionary = {
			[ChatMessageStatus.QUEUED]: { icon: "clock", style: "color: #050505;", class: "h6 m-0" },
			[ChatMessageStatus.SENT]: { icon: "check", style: "color: #050505;", class: "h4 m-0" },
			[ChatMessageStatus.DELIVERED]: { icon: "check-all", style: "color: #050505;", class: "h4 m-0" },
			[ChatMessageStatus.READ]: { icon: "check-all", style: "color: #43bbe9;", class: "h4 m-0" },
			[ChatMessageStatus.ERROR]: { icon: "exclamation-circle", style: "color: #d90b34;", class: "h4 m-0" },
			[ChatMessageStatus.FAILED]: { icon: "exclamation-circle", style: "color: #d90b34;", class: "h4 m-0" },
			[ChatMessageStatus.UNDELIVERED]: { icon: "exclamation-circle", style: "color: #d90b34;", class: "h4 m-0" },
		};
		return { ...bootstrapIconDictionary[status] };
	}

	currentUserStyleMessenger = "flex flex-col bg-journey-weak-green rounded-br-none px-1 py-0.5 w-full rounded";
	clientStyleMessenger = "flex flex-col bg-gray-300 rounded-bl-none px-1 py-0.5 w-full rounded";

	messageLayout(isUser: boolean) {
		return isUser ? this.currentUserStyleMessenger : this.clientStyleMessenger;
	}
}
