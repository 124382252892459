

















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BOverlay } from "bootstrap-vue";
import { ChatChannel, ChatChannelStatus } from "@/interfaces/chat";
import chatModel from "@/api/chat.model";
import CustomButton from "./Button.vue";
import ChatMemberCard from "./ChatMemberCard.vue";
import { showErrorAlert } from "@/helpers";

@Component({
	components: {
		BOverlay,
		"custom-button": CustomButton,
		"chat-member-card": ChatMemberCard,
	},
})
export default class ChooseChannel extends Vue {
	@Prop() id: string;
	@Prop({ default: "" }) modalStyle!: string;
	@Prop({ default: "" }) title!: string;
	@Prop({ default: "" }) titlesStyle!: string;
	@Prop({ default: "" }) subtitle!: string;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "" }) iconSize!: string;
	@Prop({ default: "mb-2" }) backButtonStyle!: string;
	@Prop({ default: "Voltar" }) backButtonText!: string;
	@Prop({ default: false }) isOpen!: boolean;
	@Prop({ default: false }) withBackButton!: boolean;
	@Prop({ default: null }) onBackButton!: Function;
	@Prop({ default: () => [] }) buttons!: any[];
	@Prop({ default: () => null }) onModalClosed!: () => void;

	isModalOpen = this.isOpen;
	chatModel = chatModel;

	isChannelsLoading = false;

	activeUser = this.$store.state.auth.activeUser;
	queueChannelList: ChatChannel[] = [];

	mainChatChannelMember: { fullName: string; id: string } = {
		fullName: "",
		id: "",
	};

	data() {
		return { isModalOpen: this.isOpen };
	}

	closeModal() {
		this.isModalOpen = false;
		if (this.onModalClosed && typeof this.onModalClosed === "function") {
			this.onModalClosed();
		}
	}
	@Watch("isOpen")
	async isOpenChanged() {
		this.isModalOpen = this.isOpen;
		if (this.isModalOpen) {
			await this.fetchChannels();
		}
	}

	openChannel(cardData: ChatChannel) {
		this.$emit("openChannel", { phoneNumber: cardData.phoneNumber }, true, undefined, cardData.id);
		this.closeModal();
	}

	async fetchChannels() {
		try {
			this.isChannelsLoading = true;
			this.queueChannelList = await this.chatModel.getChannels({
				status: ChatChannelStatus.OPENED,
				shouldGetQueueChannels: true,
			});
			this.isChannelsLoading = false;
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		}
	}
}
