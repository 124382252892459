

































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BOverlay } from "bootstrap-vue";
import { ChatChannel, ChatChannelStatus } from "@/interfaces/chat";
import chatModel from "@/api/chat.model";
import CustomButton from "./Button.vue";
import ChatMemberCard from "./ChatMemberCard.vue";
import { showErrorAlert } from "@/helpers";
import { getMask, isValidPhoneNumber } from "@/helpers/utils";
import Input from "@/layouts/components/Input.vue";
import { default as Select } from "@/layouts/components/Select.vue";
import CorporateClientAppModel from "@/api/corporate_client_app.model";

@Component({
	components: {
		BOverlay,
		"custom-button": CustomButton,
		"chat-member-card": ChatMemberCard,
		"journey-input": Input,
		Select,
	},
})
export default class OpenChannelWithNumber extends Vue {
	@Prop() id: string;
	@Prop({ default: "" }) modalStyle!: string;
	@Prop({ default: "" }) title!: string;
	@Prop({ default: "" }) titlesStyle!: string;
	@Prop({ default: "" }) subtitle!: string;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "" }) iconSize!: string;
	@Prop({ default: "mb-2" }) backButtonStyle!: string;
	@Prop({ default: "Voltar" }) backButtonText!: string;
	@Prop({ default: false }) isOpen!: boolean;
	@Prop({ default: false }) withBackButton!: boolean;
	@Prop({ default: null }) onBackButton!: Function;
	@Prop({ default: () => [] }) buttons!: any[];
	@Prop({ default: () => null }) onModalClosed!: () => void;

	isModalOpen = this.isOpen;
	chatModel = chatModel;

	buttonDisabled = true;

	clientPhoneNumberText: string = "";
	corporateClientIdFilter?: string = "";

	isCorporateClientLoading = false;

	activeUser = this.$store.state.auth.activeUser;
	queueChannelList: ChatChannel[] = [];

	corporateClientOptions: { name: string; value: string }[] = [];
	corporateClientAppModel = CorporateClientAppModel;

	mainChatChannelMember: { fullName: string; id: string } = {
		fullName: "",
		id: "",
	};

	data() {
		return { isModalOpen: this.isOpen };
	}

	closeModal() {
		this.isModalOpen = false;
		if (this.onModalClosed && typeof this.onModalClosed === "function") {
			this.onModalClosed();
		}
	}
	@Watch("isOpen")
	async isOpenChanged() {
		this.isModalOpen = this.isOpen;
		if (this.isModalOpen) {
			this.clientPhoneNumberText = "";
			this.corporateClientIdFilter = "";
			await this.fetchCorporateClientOptions();
		}
	}

	@Watch("clientPhoneNumberText")
	@Watch("corporateClientIdFilter")
	changeButtonStatus() {
		if (isValidPhoneNumber(this.clientPhoneNumberText) && this.corporateClientIdFilter) {
			this.buttonDisabled = false;
		} else {
			this.buttonDisabled = true;
		}
	}

	async fetchCorporateClientOptions() {
		const corporateClients = await this.corporateClientAppModel.search({ page: 1, limit: 0 });
		this.corporateClientOptions = corporateClients.data.length
			? corporateClients.data.map((corporateClient: any) => {
					return { name: corporateClient.name, value: corporateClient.id };
			  })
			: [];
	}

	createOrOpenConversationOnlyWithPhoneNumber() {
		this.$emit("openChannel", { phoneNumber: this.clientPhoneNumberText }, false, this.corporateClientIdFilter);
		this.clientPhoneNumberText = "";
		this.corporateClientIdFilter = "";
		this.closeModal();
	}

	inputMask() {
		return getMask("tel");
	}
}
