






























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CustomButton from "@/layouts/components/Button.vue";
import Select from "@/layouts/components/Select.vue";
import userAppModel from "@/api/user_app.model";
import { showErrorAlert } from "@/helpers";
import Input from "@/layouts/components/Input.vue";
import CustomUserCard from "./UserCard.vue";
import { IUser } from "@/interfaces/user";

@Component({
	components: {
		"custom-button": CustomButton,
		Select,
		"journey-input": Input,
		"user-card": CustomUserCard,
	},
})
export default class ChooseUser extends Vue {
	@Prop() id: string;
	@Prop({ default: "" }) modalStyle!: string;
	@Prop({ default: "" }) title!: string;
	@Prop({ default: "" }) titlesStyle!: string;
	@Prop({ default: "" }) subtitle!: string;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "" }) iconSize!: string;
	@Prop({ default: "mb-2" }) backButtonStyle!: string;
	@Prop({ default: "Voltar" }) backButtonText!: string;
	@Prop({ default: false }) isOpen!: boolean;
	@Prop({ default: "chat:search" }) eventType!: string;
	@Prop({ default: false }) withBackButton!: boolean;
	@Prop({ default: null }) onBackButton!: Function;
	@Prop({ default: () => [] }) buttons!: any[];
	@Prop({ default: () => null }) onModalClosed!: () => void;

	isModalOpen = this.isOpen;
	userModel = userAppModel;
	usersList: IUser[] = [];

	isUsersLoading: boolean = false;

	data() {
		return { isModalOpen: this.isOpen };
	}

	closeModal() {
		this.isModalOpen = false;
		if (this.onModalClosed && typeof this.onModalClosed === "function") {
			this.onModalClosed();
		}
	}
	@Watch("isOpen")
	async isOpenChanged() {
		this.isModalOpen = this.isOpen;

		if (this.isOpen) {
			await this.loadUsers();
		}
	}

	transferChannel(cardData: any) {
		this.$emit("transferChannel", cardData);
	}

	async loadUsers() {
		try {
			this.isUsersLoading = true;
			const request = await this.userModel.getWorkActiveUsers();
			this.usersList = request;
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isUsersLoading = false;
		}
	}
}
